@use '@angular/material' as mat;
@use 'variables';

.ns-dashboard-menu {
  @include mat.elevation(12);

  @media screen and (min-width: variables.$screen-xs) {
    max-width: 400px !important;
  }

  .ns-country-flag {
    margin-right: 5px !important;
  }

  .ns-value {
    color: variables.$gray-5;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ns-country-flag-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .version {
    color: variables.$gray-6;
    padding: 12px 16px;
  }
}

.ns-filter-menu {
  max-width: 90vw !important;

  @media screen and (min-width: calc(#{variables.$screen-lg} + 1px)) {
    display: none;
  }

  .ns-custom-date {
    color: variables.$gray-5;
    margin-left: 24px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ns-order-status-menu {
  .mat-mdc-menu-item-text {
    span {
      flex-grow: 1;
    }

    i {
      margin-right: 12px;
    }
  }
}
