@use '../utils';

.str-chat__main-panel-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
  align-items: center;
}

.str-chat__list {
  @include utils.scrollable;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
  max-height: 100%;

  .str-chat__message-list-scroll {
    @include utils.message-list-spacing;

    .str-chat__ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .str-chat__parent-message-li {
    $spacing: var(--str-chat__spacing-4);
    padding-block-end: $spacing;
    margin-block-end: $spacing;

    .str-chat__thread-start {
      text-align: start;
      padding-top: var(--str-chat__spacing-3);
    }
  }
}

.str-chat__jump-to-latest-message {
  position: absolute;
  inset-block-end: var(--str-chat__spacing-4);
  inset-inline-end: var(--str-chat__spacing-2);
  z-index: 2;

  .str-chat__jump-to-latest-unread-count {
    position: absolute;
    padding: var(--str-chat__spacing-0_5) var(--str-chat__spacing-2);
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
  }
}

.str-chat__main-panel {
  .str-chat__ul {
    .str-chat__li:first-of-type {
      padding-top: 4.5rem;
    }

    .str-chat__date-separator + .str-chat__li:first-of-type {
      padding-top: inherit;
    }
  }
}
