@use '../utils';

.str-chat__modal--open {
  @include utils.flex-col-center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .str-chat__modal-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.25rem 1rem;

    button.str-chat__modal-header__go-back-button,
    .str-chat__modal__close-button {
      padding: 1rem;
      background-size: 0.875rem;
      background-repeat: no-repeat;
      background-position: center;
    }

    button.str-chat__modal-header__go-back-button {
      background-image: var(--str-chat__arrow-left-icon);
    }

    .str-chat__modal-header__close-button {
      background-image: var(--str-chat__close-icon);
      background-repeat: no-repeat;
      height: 0.875rem;
      width: 0.875rem;
    }

    .str-chat__modal-header__title {
      flex: 1;
    }
  }

  button.str-chat__modal__close-button {
    @include utils.unset-button;
    margin: var(--str-chat__spacing-2);
    cursor: pointer;
  }

  .str-chat__modal__close-button {
    --str-chat-icon-height: calc(var(--str-chat__spacing-px) * 28);
    @include utils.flex-row-center;
    padding: var(--str-chat__spacing-2);
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    cursor: pointer;
  }

  .str-chat__modal__inner {
    @include utils.flex-col-center;
    padding: var(--str-chat__spacing-8) var(--str-chat__spacing-4);
    width: 40%;
    max-height: 80%;
    min-width: 0;
    min-height: 0;
  }
}

.str-chat__modal--close {
  display: none;
}
