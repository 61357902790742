@use '../utils';

.str-chat__audio_recorder-container {
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 3.25rem;
  padding-inline: 1rem;
}

.str-chat__audio_recorder,
stream-voice-recorder-wavebar {
  @include utils.flex-row-center;
  gap: 0.375rem;

  button {
    @include utils.flex-row-center;
    height: 32px;
    width: 32px;
    padding: 0;
  }

  .str-chat__audio_recorder__cancel-button {
    --str-chat-icon-height: 28px;

    svg {
      height: 28px;
    }
  }

  .str-chat__audio_recorder__toggle-playback-button {
    --str-chat-icon-height: 16px;

    svg {
      height: 16px;
    }
  }

  .str-chat__audio_recorder__pause-recording-button {
    --str-chat-icon-height: 12px;

    svg {
      height: 12px;
    }
  }

  .str-chat__audio_recorder__resume-recording-button {
    --str-chat-icon-height: 24px;

    svg {
      height: 24px;
    }
  }

  .str-chat__audio_recorder__stop-button {
    --str-chat-icon-height: 12px;

    svg {
      height: 12px;
    }
  }

  .str-chat__audio_recorder__complete-button {
    --str-chat-icon-height: 16px;

    svg {
      height: 16px;
    }
  }

  .str-chat__recording-timer {
    display: flex;
    align-items: center;
    width: 3rem;
  }

  .str-chat__recording-timer--hours {
    width: 3.75rem;
  }

  .str-chat__wave-progress-bar__track-container {
    padding-block: 0.5rem;
    overflow-x: auto;
    height: fit-content;
  }

  .str-chat__waveform-box-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .str-chat__audio_recorder__waveform-box,
  .str-chat__wave-progress-bar__track {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    height: 2rem;
    margin-inline: 1rem;
  }

  .str-chat__wave-progress-bar__track {
    width: 120px;
  }

  .str-chat__audio_recorder__waveform-box {
    overflow-x: hidden;
  }
}

.str-chat__audio_recorder-container {
  .str-chat__message-attachment__voice-recording-widget {
    display: flex;
    gap: var(--str-chat__spacing-1_5);

    .str-chat__message-attachment-voice-recording-widget--first-row {
      display: none;
    }

    .str-chat__message-attachment__voice-recording-widget__audio-state {
      display: flex;
      gap: var(--str-chat__spacing-1_5);

      .str-chat__message-attachment__voice-recording-widget__timer {
        min-width: 3rem;
        align-self: center;
      }
    }

    .str-chat__message-attachment__voice-recording-widget__right-section {
      display: none;
    }
  }
}
