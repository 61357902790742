/* --------------------

KORDE TEXT STYLES

Created by Valdemar Rolfsen 2016

-------------------- */

// Imports
// -------------------

@import "variables";

// h1 { font-size: 5em; }
// h2 { font-size: 4em; }
// h3 { font-size: 3em; }
// h4 { font-size: 2em; }
// h5 { font-size: 1.75em; }
// h6 { font-size: 1.25em; }

h1, h2, h3, h4, h5, h6, p {
  // margin: 0.1em 0;
}

// General paragraph style
// -------------------
p {
  white-space: pre-line;
}

p.big {
  font-size: 1.5em;
}

// Font weights
// -------------------

.t-100 { font-weight: 100; }

.t-300 { font-weight: 300; }

.t-500 { font-weight: 500; }

.t-400 { font-weight: 400; }

.t-600 { font-weight: 600; }

.t-800 { font-weight: 800; }

// Text position
// -------------------

.t-center { text-align: center; }

.t-left { text-align: left; }

.t-right { text-align: right; }

.t-justify { text-align: justify; }

// Font sizes
// -------------------

@for $i from 10 to 51 {
  .fs-#{$i} {
     font-size: #{$i/10}em;
  }
}

// Other
.t-upper {
  text-transform: uppercase;
}
