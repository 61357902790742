@use 'variables';

.ns-tag {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__header {
    margin-bottom: 2px;
    color: variables.$gray-8;
    font-weight: variables.$font-weight-bold;

    .ns-default-tag-label {
      display: inline-flex;
      color: variables.$green-success-5;
      background-color: variables.$green-success-1;
      padding: 0 8px;
      margin-left: 6px;
      border-radius: 12px;

      @media screen and (max-width: variables.$screen-sm) {
        margin-left: 0;
      }
    }
  }

  &__description {
    color: variables.$gray-6;
  }
}
