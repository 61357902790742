@use 'variables';
@use 'mixins';
@use 'functions';

// TODO: remove this ↓ after redesign is completed
@import '../lib/mimas/main';

// TODO: remove after chat refactoring is complete
// @import 'stream-chat-angular/src/assets/styles/scss/index.scss';
// @import 'stream-chat-angular/src/assets/styles/v2/scss/index.scss';
// @import 'stream-chat-angular/src/assets/styles/v2/scss/index.layout.scss';

// TODO: remove this ↓ after redesign is completed
.tf-relative {
  position: relative;
}

$widths: 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70;
@each $width in $widths {
  .ns-width-#{$width} {
    width: #{$width + '%'};
  }
}

// Don't use scrollbar-width when a more customizable ::-webkit-scrollbar properties are supported
@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-width: thin;
  }
}

@media (hover: hover) {
  ::-webkit-scrollbar {
    width: var(--scrollbar-size);
    height: var(--scrollbar-size);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
    border-radius: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: calc(var(--scrollbar-size) / 2);
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--scrollbar-track-color);
  }
}

::selection {
  color: white;
  background-color: variables.$gray-7;
}

body {
  --scrollbar-size: #{variables.$scrollbar-size};
  @include mixins.scrollbar-theme(variables.$gray-2, variables.$gray-4);
  @include mixins.nunito;
  overflow: hidden;
  height: functions.vh(100);
  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: variables.$screen-sm) {
    --scrollbar-size: calc(#{variables.$scrollbar-size} / 2);
  }
}

a {
  outline: none;
  text-decoration: none;
}

[type='date'],
[type='time'],
[type='datetime-local'] {
  z-index: 2 !important;
}

.mat-mdc-input-element {
  &[type='date']::-webkit-calendar-picker-indicator,
  &[type='time']::-webkit-calendar-picker-indicator,
  &[type='datetime-local']::-webkit-calendar-picker-indicator {
    height: 100%;
    width: 50px;
    position: absolute;
    left: -56px;
    top: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
}

mat-tooltip-component .mat-mdc-tooltip {
  &.ns-light .mdc-tooltip__surface {
    color: variables.$gray-9;
    background-color: white;
  }

  &.ns-dark .mdc-tooltip__surface {
    color: white;
    background-color: variables.$gray-8;
  }

  &.ns-sidebar-tooltip {
    .mdc-tooltip__surface {
      font-size: 14px;

      @media screen and (max-width: variables.$screen-sm) {
        display: none;
      }
    }
  }

  .mdc-tooltip__surface {
    max-width: 250px;
    font-size: 12px;
    line-height: 1.5;
    padding: 6px 12px;
    border-radius: variables.$radius-small;
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    user-select: none;
  }
}

strong,
b {
  font-weight: 700;
}

em {
  font-style: italic;
}

.ns-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;

  .ns-page-header {
    display: flex;
    gap: 8px;
    align-items: stretch;
    padding: 14px 28px;
    background-color: white;
    // Triggers a Webkit bug hack-fixed in webkit-hacks.scss
    z-index: variables.$z-page-header;

    @media (width <= variables.$screen-lg) {
      gap: 4px;
    }

    @media (width <= variables.$screen-sm) {
      padding: 4px;
    }

    .ns-group-settings-button {
      border-radius: variables.$radius-small;

      .ns-button {
        border-radius: inherit;
      }
      .ns-button__icon {
        margin: 0;
      }
    }
  }

  .ns-page-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: variables.$gray-1;

    &--white {
      background-color: white;
    }

    &--scrollable {
      overflow: auto;
    }

    &:not(.ns-page-body--empty) {
      padding: variables.$page-body-block-padding variables.$page-body-inline-padding;

      @media screen and (max-width: variables.$screen-sm) {
        padding: variables.$page-body-block-padding variables.$page-body-inline-padding-mobile;
      }
    }
  }
}

.ns-boolean {
  display: inline-flex;
  user-select: none;

  &.gray {
    color: variables.$gray-4;
  }

  &.red {
    color: variables.$red-error-4;
  }

  &.green {
    color: variables.$green-success-5;
  }
}

ul.ns-bulleted {
  &--gray {
    li:before {
      color: variables.$gray-5;
    }
  }

  li:before {
    content: '•';
    margin: 0 7px;
  }
}

.ns-list {
  &__item {
    border-radius: 2px;
    transition-duration: 0.2s;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.ns-list__item--selected {
      > .ns-list__button {
        &:not(.ns-list__button--no-shadow) {
          box-shadow:
            0px 2px 6px rgba(191, 191, 191, 0.4),
            0px 5px 12px rgba(50, 49, 73, 0.08);
        }

        &:before {
          transform: translate(0);
        }
      }
    }
  }

  &__button {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 18px 20px;
    background-color: transparent;
    border-radius: 2px;
    font: inherit;
    text-align: left;
    outline: none;
    user-select: none;
    transition-duration: inherit;

    &--white {
      background-color: white;
    }

    &:not(.ns-list__button--no-shadow) {
      &:hover,
      &:focus {
        box-shadow:
          0px 2px 6px rgba(191, 191, 191, 0.4),
          0px 5px 12px rgba(50, 49, 73, 0.08);
      }
    }

    &:before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition-duration: inherit;
      transform: translateX(-4px);
      border-radius: 2px;
      background-color: variables.$red-primary-5;
    }

    &--with-shadow {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    }

    &--active {
      &:not(.ns-list__button--no-shadow) {
        box-shadow:
          0px 2px 6px rgba(191, 191, 191, 0.4),
          0px 5px 12px rgba(50, 49, 73, 0.08);
      }

      &:before {
        transform: translate(0);
      }
    }

    h6 {
      color: variables.$gray-8;
    }
  }
}

.ns-filter-tabs {
  &__full-size {
    display: flex;
    gap: 12px;
  }

  &__compact {
    .ns-button {
      border-radius: variables.$radius-small;
    }
  }
}

.ns-filter-tab {
  flex-shrink: 0;

  &__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.5;
    font-weight: variables.$font-weight-bold;
    padding: 3px 14px;
    color: variables.$gray-6;
    background-color: white;
    border: 1px solid transparent;
    border-radius: variables.$radius-small;
    transition-duration: 0.2s;
    outline: none;

    &:focus-visible:not(:hover, :active) {
      border-color: variables.$red-primary-2;
    }

    &:not(:disabled, .ns-filter-tab__button--active) {
      @media (hover: hover) {
        &:hover {
          color: variables.$gray-7;
          background-color: variables.$gray-3;
        }
      }

      &:active {
        color: variables.$gray-7;
        background-color: variables.$gray-4;
      }
    }

    &:disabled {
      color: variables.$gray-4;
      background-color: variables.$gray-2;
      cursor: default;
    }

    &--active {
      color: white;
      background-color: variables.$gray-7;
    }
  }
}

.ns-copy-url {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  outline: none;
  border: 1px solid variables.$gray-2;
  border-radius: variables.$radius-small;
  background-color: variables.$gray-1;
  transition-duration: 0.2s;

  &:not(.disabled) {
    color: variables.$gray-9;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: variables.$gray-2;
      border-color: variables.$gray-3;
    }
  }

  &.disabled {
    color: variables.$gray-5;
    cursor: default;
    user-select: none;
  }

  .ns-url {
    margin-right: 16px;

    @include mixins.ellipsis();
  }
}

.ns-field-with-switcher {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ns-switcher-field {
  width: 100%;
  position: relative;
  background-color: variables.$gray-1;
  border: 1px solid variables.$gray-2;
  border-radius: variables.$radius-small;

  .mdc-form-field {
    display: flex;
    position: relative;
  }

  label {
    --mat-slide-toggle-label-text-line-height: 1.5;

    min-height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 12px 76px 12px 12px !important;
    color: variables.$gray-7;
    user-select: none;
    cursor: pointer;
    transition-duration: 0.2s;
  }

  .mdc-switch {
    position: absolute;
    right: 20px;

    &--disabled + label {
      color: variables.$gray-4 !important;
      pointer-events: none;
    }
  }

  .bold {
    font-weight: variables.$font-weight-bold;
  }
}

.ns-checkbox-field,
.ns-radio-button {
  background-color: variables.$gray-1;
  border: 1px solid variables.$gray-2;
  border-radius: variables.$radius-small;

  .mat-mdc-checkbox,
  .mat-mdc-radio-button {
    display: block;
  }

  .mdc-form-field {
    display: flex;
    position: relative;
  }

  label {
    min-height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 12px 56px 12px 12px !important;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    color: variables.$gray-7;
    user-select: none;
    transition-duration: 0.2s;
    cursor: pointer;
  }

  .mdc-checkbox,
  .mdc-radio {
    position: absolute !important;
    right: 8px;

    &--disabled label {
      color: variables.$gray-4;
    }
  }

  .bold {
    font-weight: variables.$font-weight-bold;
  }
}

.ns-radio-button:not(:last-child) {
  margin-bottom: 16px;
}

// Requires adding `style="--columns: n"` attribute.
.ns-mini-table {
  $cell-side-padding: 12px;

  display: grid;
  grid-template-columns: repeat(var(--columns), auto);
  gap: 4px 0;
  color: variables.$gray-7;
  margin: 0 -#{$cell-side-padding};
  overflow-x: auto;

  &__row {
    display: contents;
    cursor: default;

    &:hover {
      .ns-mini-table__cell {
        background-color: variables.$gray-2;
      }
    }

    .ns-mini-table__cell:first-child {
      border-radius: variables.$radius-small 0 0 variables.$radius-small;
    }

    .ns-mini-table__cell:last-child {
      border-radius: 0 variables.$radius-small variables.$radius-small 0;
    }
  }

  &__cell {
    display: flex;
    padding: 0 $cell-side-padding;
    transition-duration: 0.3s;

    &--align-right {
      justify-content: flex-end;
    }

    &--align-center {
      justify-content: center;
    }

    i {
      font-size: 20px;
      user-select: none;
    }
  }

  &__header-cell {
    user-select: none;
    font-weight: variables.$font-weight-bold;
  }
}

.ns-badge {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  height: 18px;
  font-weight: variables.$font-weight-bold;
  padding-inline: 4px;
  border: 1px solid transparent;
  border-radius: 12px;
  user-select: none;

  &--new {
    color: white;
    background-color: variables.$red-primary-5;
  }

  &--beta {
    color: #a0c5f8;
    border-color: #a0c5f8;
  }
}

.ns-expandable-details {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 350px;
  box-shadow: variables.$box-shadow-light;
  border-radius: variables.$radius-large;
  background-color: white;
  overflow: hidden;

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 16px;
    background-color: transparent;
    outline: none;
    transition-duration: 0.2s;

    @media (hover: hover) {
      &:not(.ns-expandable-details__button--active) {
        &:hover {
          background-color: variables.$gray-2;
        }
      }
    }

    &:focus {
      background-color: variables.$gray-2;
    }

    i,
    mat-icon {
      color: variables.$gray-5;
      transition: 0.2s;

      &.active {
        transform: rotateZ(180deg);
      }
    }

    .button-text {
      h6 {
        color: variables.$gray-9;
        margin-bottom: 2px;
      }

      p {
        color: variables.$gray-6;
        font-weight: normal;
        display: flex;
        align-items: center;

        mat-icon {
          @include mixins.icon-size(18px);

          margin-left: 2px;
        }
      }
    }
  }

  &__body {
    padding: 0 16px;
    margin-bottom: 16px;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px 0;
  color: variables.$gray-6;
  overflow: hidden;
  user-select: none;

  @media screen and (min-width: calc(#{variables.$screen-sm} + 1px)) {
    flex-wrap: wrap;
  }

  li {
    display: flex;
    align-items: center;

    a, button {
      &:not(.current) {
        @media (hover: hover) {
          &:hover {
            background-color: variables.$gray-2;
          }
        }
  
        &:focus-within {
          background-color: variables.$gray-2;
        }
      }
    }

    &.placeholder {
      @media screen and (min-width: calc(#{variables.$screen-sm} + 1px)) {
        display: none;
      }

      button {
        display: flex;
        gap: 10px;
        color: inherit;
      }
    }

    &:last-child {
      overflow: hidden;

      a {
        @include mixins.ellipsis();
      }
    }

    &:not(:last-child) {
      flex-shrink: 0;
    }

    @media screen and (max-width: variables.$screen-sm) {
      &:not(.root, .placeholder, :last-child) {
        display: none;
      }
    }
  }

  a {
    color: inherit;
    padding: 0 3px;
    margin: 0 1px;

    &.current {
      color: variables.$gray-9;
      pointer-events: none;
    }
  }

  button {
    outline: none;
    background-color: transparent;
  }

  a, button {
    border-radius: variables.$radius-small;
    transition-duration: 0.2s;
  }

  .desktop-link {
    display: flex;
    align-items: center;
  
    @media (width <= variables.$screen-sm) {
      display: none;
    }
  
    mat-icon {
      margin-right: 4px;
    }
  }
  
  .mobile-link {
    display: flex;
    align-items: center;
    gap: 4px;
  
    @media (width > variables.$screen-sm) {
      display: none;
    }
  
    span {
      @include mixins.ellipsis();
    }
  }

  mat-icon {
    flex-shrink: 0;

    @include mixins.icon-size(20px);
  }
}

.ns-icon-huge {
  font-size: 120px;
}

.icon-two-tone {
  filter: invert(65%) sepia(24%) saturate(191%) hue-rotate(197deg) brightness(91%) contrast(90%);
}

.ns-clickable {
  cursor: pointer;
}

// TODO: remove once login page is redesigned
.ns-center-content-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .ns-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-height: 100vh;
    width: 360px;
    overflow: auto;
    padding: 24px 24px 80px;

    img {
      margin-bottom: 36px;
    }

    form,
    p {
      width: 100%;
      text-align: left;
      margin-bottom: 24px;
    }

    app-button {
      margin-bottom: 12px;
    }
  }
}

// TODO: remove after complete redesign is done
.dash-card {
  margin-bottom: 20px;
  @include trans($time: 400ms);

  .dash-card__content {
    .content__container {
      position: absolute;
      z-index: 4;
      top: calc(-30px / 2);
      right: calc(30px / 2);

      .content__edit {
        height: 30px;
        padding: 0 20px;
        border-radius: calc(30px / 2);
        margin: 0 5px;
        box-shadow:
          0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24);
        // Text
        text-align: center;

        span {
          line-height: 30px;
        }
      }
    }
  }
}
