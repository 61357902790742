/*

KORDE PROPERTIES

Created by Valdemar Rolfsen 2016

*/

@import "mixins";

// Padding & Margins
// --------------------
@for $i from 0 to 4 {
  @for $j from 0 to 4 {
    .p-l-#{$i*5}-#{$j*5} {
      padding: #{$i*5}px #{$j*5}px;
    }

    .m-l-#{$i*5}-#{$j*5} {
      margin: #{$i*5}px #{$j*5}px;
    }
  }
}

@for $i from 0 to 11 {
  @for $j from 0 to 11 {
    .p-l-#{$i*20}-#{$j*20} {
      padding: #{$i*20}px #{$j*20}px;
    }

    .m-l-#{$i*20}-#{$j*20} {
      margin: #{$i*20}px #{$j*20}px;
    }
  }
}

@media (max-width: $smallMediaSize) {
  @for $i from 0 to 4 {
    @for $j from 0 to 4 {
      .p-s-#{$i*5}-#{$j*5} {
        padding: #{$i*5}px #{$j*5}px;
      }

      .m-s-#{$i*5}-#{$j*5} {
        margin: #{$i*5}px #{$j*5}px;
      }
    }
  }

  @for $i from 2 to 22 {
    @for $j from 0 to 22 {
      .p-s-#{$i*10}-#{$j*10} {
        padding: #{$i*10}px #{$j*10}px;
      }

      .m-s-#{$i*10}-#{$j*10} {
        margin: #{$i*10}px #{$j*10}px;
      }
    }
  }
}

.p-top {
  padding-bottom: 0;
}

.p-bottom {
  padding-top: 0;
}

.p-right {
  padding-left: 0;
}

.p-left {
  padding-right: 0;
}

.m-top {
  margin-bottom: 0;
}

.m-right {
  margin-left: 0;
}

.m-left {
  margin-right: 0;
}

.m-bottom {
  margin-top: 0;
}

// Height & Width
// --------------------

@for $i from 1 to 160 {
  .h-#{$i*10} {
    height: #{$i*10}px;
  }

  .w-#{$i*10} {
    width: #{$i*10}px;
  }
}

@media (max-width: $smallMediaSize) {
  @for $i from 1 to 70 {
    .h-s-#{$i*10} {
      height: #{$i*10}px;
    }
  }
}

@for $i from 1 to 11 {
  .h-#{$i*10}-p {
    height: $i*10%;
  }

  .w-#{$i*10}-p {
    width: $i*10%;
  }

  // Cover opacity
  .cover-#{$i} {
    &:before {
      opacity: 0.1*$i;
    }
  }
}

.h-full {
  min-height: 100vh;
}

// Spinner
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.spinner {
  min-width: 16px;
  min-height: 16px;
  position: relative;
  margin: 5px;
}

.spinner:before {
  content: 'Loading…';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin-top: -10px;
  margin-left: -10px;
}

.spinner:not(:required):before {
  content: '';
  border-radius: 50%;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  animation: spinner .6s linear infinite;
  -webkit-animation: spinner .6s linear infinite;
}

// Background, Color, Border, Hover and Cover
// --------------------

@each $item in $mainColors {

  $name: nth($item, 1);
  $bg: nth($item, 2);
  $color: nth($item, 3);
  $hover: nth($item, 4);

  // Color options for main colors
  .color-#{$name} {
    color: $bg;

    a {
      color: $bg;
    }
  }

  // Background options for main colors
  .bg-#{$name} {
    background: $bg;
    color: $color;

    .input-bottom-#{$name} {
      color: $color;
      &:focus {
        border-bottom-color: $color;
      }
    }
  }

  // Border color options for main colors
  .border-#{$name} {
    border-color: $bg;
  }

  .cover-#{$name} {

    &:before {
      @extend %cover;
      background: $bg;
    }
  }

  .hover-#{$name} {
    @extend %hover;

    &:hover {
      background: $hover;
    }
  }

  .spinner-#{$name}:not(:required):before {
    border-top-color: $bg;
  }
}

.bg-image {
  background-position: center center;
  background-size: cover;
}

.cover-grad {
  &:before {
    @extend %cover;
    @include grad-down(transparent, rgba(0,0,0,0.7));
  }
}

// Other cover
.cover-content {
  position: relative;
  z-index: 1;
}

// Border width
@for $i from 0 to 10 {
  .border-#{$i} {
    @extend %solid-border;
    border-width: #{$i}px;
  }

  .border-b-#{$i} {
    @extend %solid-bottom-border;
    border-bottom-width: #{$i}px;
  }

  .border-t-#{$i} {
    @extend %solid-top-border;
    border-top-width: #{$i}px;
  }

  .border-r-#{$i} {
    @extend %solid-right-border;
    border-right-width: #{$i}px;
  }
  .border-l-#{$i} {
    @extend %solid-left-border;
    border-left-width: #{$i}px;
  }
}

// Positioning
// --------------------

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}

.is-fixed {
  position: fixed;
}

.is-absolute {
  position: absolute;
}

.block-center {
  display: block;
  margin: 0 auto;
}

.float-right {
  float: right;
}

// Shapes
// --------------------

.circle {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

@for $i from 1 to 11 {
  .rounded-#{$i} {
    border-radius: #{$i}px;
  }
}

// Shadows
// --------------------

.center-shadow {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.right-shadow {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

// Cover
// --------------------

// Mobile
// --------------------
.on-mobile {
  display: none;
}

@media (max-width: $smallMediaSize) {
  .hide-mobile {
    display: none;
  }
  .on-mobile {
    display: block;
  }
}

// Lists
// --------------------

// Overflow
// --------------------

.overflow-auto {
  overflow-y: auto;
}

.is-inline {
  display: inline;
}
