@use '../utils';

// todo: React SDK specific. Copied from V1. Deprecate after merging with MessageList
.str-chat__virtual-list {
  @include utils.scrollable;
  position: relative;
  flex: 1;
  -webkit-overflow-scrolling: touch; /* enable smooth scrolling on ios */
  margin: 0;
  width: 100%;
  height: 100%;

  .str-chat__message-list-scroll {
    overscroll-behavior: none;
  }

  .str-chat__message-list-scroll > div {
    @include utils.message-list-spacing;
  }

  .str-chat__parent-message-li {
    padding-block-end: var(--str-chat__spacing-4);

    .str-chat__thread-start {
      text-align: start;
      padding-top: var(--str-chat__spacing-3);
    }
  }

  // conditionally showing the header displaces items when prepending.
  // a simple workaround is to make the loading indicator an overlay.
  &__loading {
    display: flex;
    padding-top: var(--str-chat__spacing-2);
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  p {
    margin: 0 !important; // always use padding, margin mess up the height calculations

    a {
      white-space: pre-line;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  .str-chat__message {
    margin-block-end: 0 !important;
  }

  .str-chat__virtual-list-message-wrapper {
    padding-block-end: var(--str-chat__spacing-0_5);
  }
}
