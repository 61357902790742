@use '@angular/material' as mat;
@use 'variables';

$custom-typography: mat.m2-define-typography-config(
  $font-family: variables.$nunito
);
@include mat.all-component-typographies($custom-typography);

$custom-primary-palette: (
  50: variables.$red-primary-1,
  100: variables.$red-primary-2,
  200: variables.$red-primary-3,
  300: variables.$red-primary-4,
  400: variables.$red-primary-5,
  500: variables.$red-primary-6,
  600: variables.$red-primary-7,
  700: variables.$red-primary-8,
  800: variables.$red-primary-9,

  contrast: (
    500: white
  )
);

$custom-secondary-palette: (
  50: variables.$gray-2,
  100: variables.$gray-3,
  200: variables.$gray-4,
  300: variables.$gray-5,
  400: variables.$gray-6,
  500: variables.$gray-7,
  600: variables.$gray-8,
  700: variables.$gray-9,
  800: variables.$gray-10,

  contrast: (
    500: white
  )
);

$custom-primary: mat.m2-define-palette($custom-primary-palette, 500);
// $custom-accent: mat.define-palette($custom-secondary-palette, 500);

$custom-primary-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-primary
    )
  )
);

// $custom-accent-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $custom-accent,
//       accent: $custom-accent
//     )
//   )
// );

@include mat.slide-toggle-theme($custom-primary-theme);
// @include mat.checkbox-theme($custom-accent-theme);

// New Angular Material style customizations
// Make the outline thicker
[class*='mdc-notched-outline__'] {
  border-width: 2px !important;
}

// Make the native datepicker icon clickable. It's hidden with opacity in main.scss.
input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

// Undo https://github.com/angular/components/commit/98f610d192015599d8e0ce1a0f2a455c31ced558#diff-e0fe8fc58b7389cf4396d748bff2128276a9b3b1b33a5822f6e2e494ab933618
.mdc-notched-outline__notch {
  border-left: none !important;
  margin-left: 0 !important;
  clip-path: none !important;
}

// Compensate a thicker border
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  margin-top: -1px;
}

// Compensate a thicker border
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 15px;
  width: auto;
}

.mat-form-field-appearance-outline {
  --mdc-outlined-text-field-outline-color: #{variables.$gray-3};
  --mdc-outlined-text-field-hover-outline-color: #{variables.$gray-5};
  --mdc-outlined-text-field-focus-outline-color: #{variables.$gray-7};
  --mdc-outlined-text-field-disabled-outline-color: #{variables.$gray-2};
  --mdc-outlined-text-field-error-outline-color: #{variables.$red-error-5};
  --mdc-outlined-text-field-error-hover-outline-color: #{variables.$red-error-4};
  --mdc-outlined-text-field-error-focus-outline-color: #{variables.$red-error-4};

  --mdc-outlined-text-field-label-text-color: #{variables.$gray-10};
  --mdc-outlined-text-field-hover-label-text-color: #{variables.$gray-10};
  --mdc-outlined-text-field-focus-label-text-color: #{variables.$gray-10};
  --mdc-outlined-text-field-disabled-label-text-color: #{variables.$gray-5};
  --mdc-outlined-text-field-error-label-text-color: #{variables.$red-error-6};
  --mdc-outlined-text-field-error-hover-label-text-color: #{variables.$red-error-5};
  --mdc-outlined-text-field-error-focus-label-text-color: #{variables.$red-error-5};

  --mdc-outlined-text-field-input-text-color: #{variables.$gray-9};
  --mdc-outlined-text-field-disabled-input-text-color: #{variables.$gray-5};
  --mdc-outlined-text-field-caret-color: #{variables.$gray-9};
  --mdc-outlined-text-field-error-caret-color: #{variables.$red-error-5};

  // TODO: file a bug about prefix icon color
  --mat-form-field-leading-icon-color: #{variables.$gray-5};
  --mat-form-field-disabled-leading-icon-color: #{variables.$gray-5};
  --mat-form-field-error-leading-icon-color: #{variables.$red-error-5};
  --mat-form-field-error-hover-leading-icon-color: #{variables.$red-error-4};
  --mat-form-field-error-focus-leading-icon-color: #{variables.$red-error-4};
  --mat-form-field-trailing-icon-color: #{variables.$gray-5};
  --mat-form-field-disabled-trailing-icon-color: #{variables.$gray-5};
  --mat-form-field-error-trailing-icon-color: #{variables.$red-error-5};
  --mat-form-field-error-hover-trailing-icon-color: #{variables.$red-error-4};
  --mat-form-field-error-focus-trailing-icon-color: #{variables.$red-error-4};
  --mat-form-field-error-text-color: #{variables.$red-error-6};

  .mdc-text-field--outlined {
    [class*='mdc-notched-outline__'] {
      transition: border-color 0.2s;
    }

    &:not(.mdc-text-field--disabled) {
      &.mdc-text-field--invalid {
        .mdc-icon-button {
          --mdc-icon-button-icon-color: variables.$red-error-6;
        }
      }
    }

    .mdc-icon-button {
      --mdc-icon-button-icon-color: variables.$gray-6;
    }
  }

  .mdc-floating-label {
    user-select: none;
  }

  .mat-mdc-form-field-hint {
    color: variables.$gray-6;
    user-select: none;
  }

  // TODO: remove when https://github.com/angular/components/issues/27722 is fixed.
  .mat-mdc-form-field-hint,
  .mat-mdc-form-field-error {
    display: flex;
    padding-top: 2px;
  }

  .mdc-icon-button {
    margin-right: 4px;
  }

  @include mat.icon-button-density(-2);
}

.mdc-switch {
  --mdc-switch-selected-handle-color: #{variables.$red-primary-5} !important;
  --mdc-switch-selected-hover-handle-color: #{variables.$red-primary-6} !important;
  --mdc-switch-selected-focus-handle-color: #{variables.$red-primary-6} !important;
  --mdc-switch-selected-pressed-handle-color: #{variables.$red-primary-7} !important;
  --mdc-switch-selected-track-color: #{transparentize(variables.$red-primary-5, 0.46)} !important;
  --mdc-switch-selected-hover-track-color: #{transparentize(variables.$red-primary-5, 0.46)} !important;
  --mdc-switch-selected-focus-track-color: #{transparentize(variables.$red-primary-5, 0.46)} !important;
  --mdc-switch-selected-pressed-track-color: #{transparentize(variables.$red-primary-5, 0.46)} !important;
  --mdc-switch-disabled-handle-opacity: 0.5 !important;
  --mdc-switch-disabled-track-opacity: 0.5 !important;
  --mdc-switch-disabled-selected-handle-color: #{variables.$red-primary-5} !important;
  --mdc-switch-disabled-selected-track-color: #{transparentize(variables.$red-primary-5, 0.46)} !important;
  --mdc-switch-disabled-unselected-handle-color: #{variables.$gray-4} !important;
  --mdc-switch-disabled-unselected-track-color: #{transparentize(variables.$gray-4, 0.62)} !important;
  --mdc-switch-unselected-handle-color: #{variables.$gray-4} !important;
  --mdc-switch-unselected-hover-handle-color: #{variables.$gray-5} !important;
  --mdc-switch-unselected-focus-handle-color: #{variables.$gray-5} !important;
  --mdc-switch-unselected-pressed-handle-color: #{variables.$gray-6} !important;
  --mdc-switch-unselected-track-color: #{transparentize(variables.$gray-4, 0.62)} !important;
  --mdc-switch-unselected-hover-track-color: #{transparentize(variables.$gray-4, 0.62)} !important;
  --mdc-switch-unselected-focus-track-color: #{transparentize(variables.$gray-4, 0.62)} !important;
  --mdc-switch-unselected-pressed-track-color: #{transparentize(variables.$gray-4, 0.62)} !important;

  .mdc-switch .mdc-switch__ripple::after {
    transition-duration: 0.2s;
  }
}

.mat-mdc-menu-item {
  --mat-menu-item-label-text-color: #{variables.$gray-8};
  --mat-menu-item-icon-color: #{variables.$gray-7};
  --mat-menu-item-hover-state-layer-color: #{variables.$gray-1};
  --mat-menu-item-focus-state-layer-color: #{darken(variables.$gray-1, 2%)};

  transition-duration: 0.2s;

  &.red {
    --mat-menu-item-label-text-color: #{variables.$red-error-5};
    --mat-menu-item-icon-color: #{variables.$red-error-5};
  }

  .mat-mdc-menu-item-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: variables.$font-weight-bold !important;
  }
}

.mat-mdc-select {
  --mat-select-enabled-trigger-text-color: #{variables.$gray-9};
}

.mat-mdc-select-arrow {
  --mat-select-enabled-arrow-color: #{variables.$gray-5};
  --mat-select-focused-arrow-color: #{variables.$gray-7};
  --mat-select-invalid-arrow-color: #{variables.$red-error-6};
  transition-duration: 0.2s;
}

.mat-mdc-option {
  --mat-option-selected-state-label-text-color: #{variables.$gray-8};
  --mat-option-selected-state-layer-color: #{variables.$gray-2};
  --mat-option-hover-state-layer-color: #{variables.$gray-1};
  --mat-option-focus-state-layer-color: #{darken(variables.$gray-1, 2%)};

  padding-block: 8px !important;
  transition-duration: 0.2s;
}

.mat-mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: white !important;
  --mdc-checkbox-selected-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-selected-hover-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-selected-focus-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-selected-pressed-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-unselected-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-unselected-hover-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-unselected-focus-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-unselected-pressed-icon-color: #{variables.$gray-7} !important;
  --mdc-checkbox-disabled-selected-icon-color: #{variables.$gray-4} !important;
  --mdc-checkbox-disabled-unselected-icon-color: #{variables.$gray-4} !important;

  .mdc-checkbox__ripple {
    background: variables.$gray-7 !important;
    transition-duration: 0.2s;
  }

  .mat-ripple-element {
    background-color: transparentize(variables.$gray-7, 0.9) !important;
  }
}

.mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #{variables.$gray-4} !important;
  --mdc-radio-disabled-unselected-icon-color: #{variables.$gray-4} !important;
  --mdc-radio-unselected-icon-color: #{variables.$gray-4} !important;
  --mdc-radio-unselected-focus-icon-color: #{variables.$gray-4} !important;
  --mdc-radio-unselected-hover-icon-color: #{variables.$gray-4} !important;
  --mdc-radio-unselected-pressed-icon-color: #{variables.$gray-4} !important;
  --mdc-radio-selected-icon-color: #{variables.$red-primary-5} !important;
  --mdc-radio-selected-focus-icon-color: #{variables.$red-primary-5} !important;
  --mdc-radio-selected-hover-icon-color: #{variables.$red-primary-5} !important;
  --mdc-radio-selected-pressed-icon-color: #{variables.$red-primary-5} !important;
  --mat-mdc-radio-ripple-color: #{variables.$gray-7} !important;
  --mat-mdc-radio-checked-ripple-color: #{variables.$red-primary-5} !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple:before {
  transition-duration: 0.2s;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #{variables.$red-primary-5};
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #{variables.$red-primary-5};
}
