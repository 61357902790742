.str-chat {
  --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 32);

  .str-chat__avatar--autocomplete-item,
  .stream-chat__avatar--autocomplete-item {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 30);
  }

  .str-chat__avatar--channel-header,
  .stream-chat__avatar--channel-header {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 40);
  }

  .str-chat__avatar--channel-preview,
  .stream-chat__avatar--channel-preview {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 49);
  }

  .str-chat__avatar--quoted-message-sender,
  .stream-chat__avatar--quoted-message-sender {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 20);
  }

  .str-chat__avatar--reaction,
  .stream-chat__avatar--reaction {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 30);
  }

  .str-chat__avatar--message-status,
  .stream-chat__avatar--message-status {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 15);
  }

  .str-chat__avatar--poll-vote-author {
    --str-chat__avatar-size: calc(var(--str-chat__spacing-px) * 20);
  }
}

.str-chat__avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--str-chat__avatar-size);
  line-height: var(--str-chat__avatar-size);
  width: var(--str-chat__avatar-size);
  min-width: var(--str-chat__avatar-size);

  &.str-chat__avatar--one-letter,
  &.stream-chat__avatar--one-letter {
    font-size: calc(var(--str-chat__avatar-size) * 0.5);
  }

  &.str-chat__avatar--no-letters {
    --str-chat-icon-width: calc(var(--str-chat__avatar-size) * 0.33);
    --str-chat-icon-height: calc(var(--str-chat__avatar-size) * 0.33);
  }

  &.str-chat__avatar--multiple-letters,
  &.stream-chat__avatar--multiple-letters {
    font-size: calc(var(--str-chat__avatar-size) * 0.3);
  }

  .str-chat__avatar-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .str-chat__avatar-fallback {
    text-align: center;
    height: inherit;
    width: inherit;
  }

  .str-chat__avatar--online-indicator {
    position: absolute;
    width: calc(var(--str-chat__spacing-px) * 12);
    height: calc(var(--str-chat__spacing-px) * 12);
    inset-inline-end: 3%;
    inset-block-start: 2%;
  }
}

.str-chat__loading-channels-avatar {
  flex-shrink: 0;
  width: calc(var(--str-chat__spacing-px) * 49);
  height: calc(var(--str-chat__spacing-px) * 49);
}


.str-chat__avatar-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: var(--str-chat__avatar-size);
  line-height: var(--str-chat__avatar-size);
  width: var(--str-chat__avatar-size);
  min-width: var(--str-chat__avatar-size);
  overflow: hidden;

  &.str-chat__avatar-group--three-part {
    .str-chat__avatar:nth-child(1) {
      grid-area: 1 / 1 / 3 / 2;
    }

    .str-chat__avatar:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    .str-chat__avatar:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  .str-chat__avatar {
    height: 100%;
    line-height: 100%;
    width: 100%;
    min-width: 100%;

    .str-chat__avatar-fallback, img {
      border-radius: 0;
    }

    &.str-chat__avatar--single .str-chat__avatar-fallback {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(var(--str-chat__avatar-size) * 0.5);
    }

    .str-chat__avatar-fallback {
      font-size: calc(var(--str-chat__avatar-size) * 0.33);
    }
  }
}