@use 'variables';

.str-chat {
  --str-chat__image-fallback-icon: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9InN0ci1jaGF0X19pbWFnZS1mYWxsYmFja19faWNvbiIgdmlld0JveD0iMCAwIDE4IDE4Ij48cGF0aCBkPSJNMTYgMnYxNEgyVjJoMTRabTAtMkgyQy45IDAgMCAuOSAwIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlYyYzAtMS4xLS45LTItMi0yWm0tNC44NiA4Ljg2LTMgMy44N0w2IDEwLjE0IDMgMTRoMTJsLTMuODYtNS4xNFoiLz48L3N2Zz4=');
  --str-chat__winning-poll-option-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9ImN1cnJlbnRDb2xvciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNS44MzMzIDQuMTY2NjdIMTQuMTY2N1YyLjVINS44MzMzM1Y0LjE2NjY3SDQuMTY2NjdDMy4yNSA0LjE2NjY3IDIuNSA0LjkxNjY3IDIuNSA1LjgzMzMzVjYuNjY2NjdDMi41IDguNzkxNjcgNC4xIDEwLjUyNSA2LjE1ODMzIDEwLjc4MzNDNi42ODMzMyAxMi4wMzMzIDcuODA4MzMgMTIuOTc1IDkuMTY2NjcgMTMuMjVWMTUuODMzM0g1LjgzMzMzVjE3LjVIMTQuMTY2N1YxNS44MzMzSDEwLjgzMzNWMTMuMjVDMTIuMTkxNyAxMi45NzUgMTMuMzE2NyAxMi4wMzMzIDEzLjg0MTcgMTAuNzgzM0MxNS45IDEwLjUyNSAxNy41IDguNzkxNjcgMTcuNSA2LjY2NjY3VjUuODMzMzNDMTcuNSA0LjkxNjY3IDE2Ljc1IDQuMTY2NjcgMTUuODMzMyA0LjE2NjY3Wk00LjE2NjY3IDYuNjY2NjdWNS44MzMzM0g1LjgzMzMzVjkuMDE2NjdDNC44NjY2NyA4LjY2NjY3IDQuMTY2NjcgNy43NSA0LjE2NjY3IDYuNjY2NjdaTTEwIDExLjY2NjdDOC42MjUgMTEuNjY2NyA3LjUgMTAuNTQxNyA3LjUgOS4xNjY2N1Y0LjE2NjY3SDEyLjVWOS4xNjY2N0MxMi41IDEwLjU0MTcgMTEuMzc1IDExLjY2NjcgMTAgMTEuNjY2N1pNMTUuODMzMyA2LjY2NjY3QzE1LjgzMzMgNy43NSAxNS4xMzMzIDguNjY2NjcgMTQuMTY2NyA5LjAxNjY3VjUuODMzMzNIMTUuODMzM1Y2LjY2NjY3WiIgZmlsbD0iIzVFNjc2RSIvPgo8L3N2Zz4K');
  --str-chat__arrow-left-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9ImN1cnJlbnRDb2xvciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0Ljc5MTUgNy4wMDUxSDMuNjIxNDhMOC41MDE0OCAyLjEyNTFDOC44OTE0OCAxLjczNTEgOC44OTE0OCAxLjA5NTEgOC41MDE0OCAwLjcwNTA5OEM4LjExMTQ4IDAuMzE1MDk4IDcuNDgxNDggMC4zMTUwOTggNy4wOTE0OCAwLjcwNTA5OEwwLjUwMTQ4NCA3LjI5NTFDMC4xMTE0ODQgNy42ODUxIDAuMTExNDg0IDguMzE1MSAwLjUwMTQ4NCA4LjcwNTFMNy4wOTE0OCAxNS4yOTUxQzcuNDgxNDggMTUuNjg1MSA4LjExMTQ4IDE1LjY4NTEgOC41MDE0OCAxNS4yOTUxQzguODkxNDggMTQuOTA1MSA4Ljg5MTQ4IDE0LjI3NTEgOC41MDE0OCAxMy44ODUxTDMuNjIxNDggOS4wMDUxSDE0Ljc5MTVDMTUuMzQxNSA5LjAwNTEgMTUuNzkxNSA4LjU1NTEgMTUuNzkxNSA4LjAwNTFDMTUuNzkxNSA3LjQ1NTEgMTUuMzQxNSA3LjAwNTEgMTQuNzkxNSA3LjAwNTFaIiBmaWxsPSIjMDgwNzA3Ii8+Cjwvc3ZnPgo=');
  --str-chat__close-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIGZpbGw9ImN1cnJlbnRDb2xvciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjI5OTcgMC43MDk5NzFDMTIuOTA5NyAwLjMxOTk3MSAxMi4yNzk3IDAuMzE5OTcxIDExLjg4OTcgMC43MDk5NzFMNi45OTk3MyA1LjU4OTk3TDIuMTA5NzMgMC42OTk5NzFDMS43MTk3MyAwLjMwOTk3MSAxLjA4OTczIDAuMzA5OTcxIDAuNjk5NzI3IDAuNjk5OTcxQzAuMzA5NzI3IDEuMDg5OTcgMC4zMDk3MjcgMS43MTk5NyAwLjY5OTcyNyAyLjEwOTk3TDUuNTg5NzMgNi45OTk5N0wwLjY5OTcyNyAxMS44OUMwLjMwOTcyNyAxMi4yOCAwLjMwOTcyNyAxMi45MSAwLjY5OTcyNyAxMy4zQzEuMDg5NzMgMTMuNjkgMS43MTk3MyAxMy42OSAyLjEwOTczIDEzLjNMNi45OTk3MyA4LjQwOTk3TDExLjg4OTcgMTMuM0MxMi4yNzk3IDEzLjY5IDEyLjkwOTcgMTMuNjkgMTMuMjk5NyAxMy4zQzEzLjY4OTcgMTIuOTEgMTMuNjg5NyAxMi4yOCAxMy4yOTk3IDExLjg5TDguNDA5NzMgNi45OTk5N0wxMy4yOTk3IDIuMTA5OTdDMTMuNjc5NyAxLjcyOTk3IDEzLjY3OTcgMS4wODk5NyAxMy4yOTk3IDAuNzA5OTcxWiIgZmlsbD0iIzA4MDcwNyIvPgo8L3N2Zz4K');
  --str-chat__add-attachment-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjggMjgiIGZpbGw9ImN1cnJlbnRDb2xvciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNS4zMzMyIDcuMzMzMDdMMTIuNjY2NiA3LjMzMzA3TDEyLjY2NjYgMTIuNjY2NEw3LjMzMzI0IDEyLjY2NjRMNy4zMzMyNCAxNS4zMzMxTDEyLjY2NjYgMTUuMzMzMUwxMi42NjY2IDIwLjY2NjRMMTUuMzMzMiAyMC42NjY0TDE1LjMzMzIgMTUuMzMzMUwyMC42NjY2IDE1LjMzMzFMMjAuNjY2NiAxMi42NjY0TDE1LjMzMzIgMTIuNjY2NEwxNS4zMzMyIDcuMzMzMDdaTTEzLjk5OTkgMC42NjY0MDRDNi42Mzk5MSAwLjY2NjQwNCAwLjY2NjU3NiA2LjYzOTc0IDAuNjY2NTc3IDEzLjk5OTdDMC42NjY1NzYgMjEuMzU5NyA2LjYzOTkxIDI3LjMzMzEgMTMuOTk5OSAyNy4zMzMxQzIxLjM1OTkgMjcuMzMzMSAyNy4zMzMyIDIxLjM1OTcgMjcuMzMzMiAxMy45OTk3QzI3LjMzMzIgNi42Mzk3NCAyMS4zNTk5IDAuNjY2NDA0IDEzLjk5OTkgMC42NjY0MDRaTTEzLjk5OTkgMjQuNjY2NEM4LjExOTkxIDI0LjY2NjQgMy4zMzMyNCAxOS44Nzk3IDMuMzMzMjUgMTMuOTk5N0MzLjMzMzI0IDguMTE5NzQgOC4xMTk5MSAzLjMzMzA3IDEzLjk5OTkgMy4zMzMwN0MxOS44Nzk5IDMuMzMzMDcgMjQuNjY2NiA4LjExOTc0IDI0LjY2NjYgMTMuOTk5N0MyNC42NjY2IDE5Ljg3OTcgMTkuODc5OSAyNC42NjY0IDEzLjk5OTkgMjQuNjY2NFoiLz4KPC9zdmc+Cg==');
  --str-chat__folder-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMTYiIGZpbGw9ImN1cnJlbnRDb2xvciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xOCAySDEwTDggMEgyQzAuOSAwIDAuMDA5OTk5OTkgMC45IDAuMDA5OTk5OTkgMkwwIDE0QzAgMTUuMSAwLjkgMTYgMiAxNkgxOEMxOS4xIDE2IDIwIDE1LjEgMjAgMTRWNEMyMCAyLjkgMTkuMSAyIDE4IDJaTTE4IDE0SDJWNEgxOFYxNFoiIC8+Cjwvc3ZnPgo=');
  --str-chat__poll-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTggMTgiIGZpbGw9ImN1cnJlbnRDb2xvciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNiAwSDJDMC45IDAgMCAwLjkgMCAyVjE2QzAgMTcuMSAwLjkgMTggMiAxOEgxNkMxNy4xIDE4IDE4IDE3LjEgMTggMTZWMkMxOCAwLjkgMTcuMSAwIDE2IDBaTTE2IDE2SDJWMkgxNlYxNlpNNCA3SDZWMTRINFY3Wk04IDRIMTBWMTRIOFY0Wk0xMiAxMEgxNFYxNEgxMlYxMFoiLz4KPC9zdmc+');
  --str-chat__handle-icon: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgNiIgZmlsbD0iY3VycmVuQ29sb3IiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTYgMEgwVjJIMTZWMFpNMCA2SDE2VjRIMFY2WiIvPgo8L3N2Zz4K');
  --str-chat__circle-stop-icon: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSI0IDQgMjggMjgiIHdpZHRoPSIzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjQ1NTEgMjcuMjQ0MUMyMi42MzA5IDI3LjI0NDEgMjcuNzQ0MSAyMi4xMTk4IDI3Ljc0NDEgMTUuOTU1MUMyNy43NDQxIDkuNzc5MyAyMi42MTk4IDQuNjY2MDIgMTYuNDQ0IDQuNjY2MDJDMTAuMjc5MyA0LjY2NjAyIDUuMTY2MDIgOS43NzkzIDUuMTY2MDIgMTUuOTU1MUM1LjE2NjAyIDIyLjExOTggMTAuMjkwNCAyNy4yNDQxIDE2LjQ1NTEgMjcuMjQ0MVpNMTYuNDU1MSAyNS4zNjI2QzExLjIzMTEgMjUuMzYyNiA3LjA1ODU5IDIxLjE3OSA3LjA1ODU5IDE1Ljk1NTFDNy4wNTg1OSAxMC43MzExIDExLjIyMDEgNi41NDc1MyAxNi40NDQgNi41NDc1M0MyMS42NjggNi41NDc1MyAyNS44NTE2IDEwLjczMTEgMjUuODYyNiAxNS45NTUxQzI1Ljg3MzcgMjEuMTc5IDIxLjY3OSAyNS4zNjI2IDE2LjQ1NTEgMjUuMzYyNlpNMTMuNTIyMSAxOS45ODM3SDE5LjM2NTlDMjAuMDYzMiAxOS45ODM3IDIwLjQ3MjcgMTkuNTc0MiAyMC40NzI3IDE4Ljg5OTFWMTMuMDExMUMyMC40NzI3IDEyLjMyNDkgMjAuMDYzMiAxMS45MjY0IDE5LjM2NTkgMTEuOTI2NEgxMy41MjIxQzEyLjgzNTkgMTEuOTI2NCAxMi40MTU0IDEyLjMyNDkgMTIuNDE1NCAxMy4wMTExVjE4Ljg5OTFDMTIuNDE1NCAxOS41NzQyIDEyLjgzNTkgMTkuOTgzNyAxMy41MjIxIDE5Ljk4MzdaIiBmaWxsPSIjMDA1ZmZmIi8+Cjwvc3ZnPg==');
}

@font-face {
  font-family: 'stream-chat-icons';
  src: url('#{variables.$assetsPath}/icons/stream-chat-icons.eot');
  src: url('#{variables.$assetsPath}/icons/stream-chat-icons.eot#iefix') format('embedded-opentype'),
    url('#{variables.$assetsPath}/icons/stream-chat-icons.woff') format('woff2'),
    url('#{variables.$assetsPath}/icons/stream-chat-icons.woff') format('woff'),
    url('#{variables.$assetsPath}/icons/stream-chat-icons.ttf') format('truetype'),
    url('#{variables.$assetsPath}/icons/stream-chat-icons.svg#stream-chat-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'stream-chat-icons';
    src: url('#{variables.$assetsPath}/icons/stream-chat-icons.svg#stream-chat-icons') format('svg');
  }
}
