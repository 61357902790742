@use '../utils';

.str-chat__link-preview-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  margin-bottom: 0.75rem;
  padding-inline: 0.75rem;
}

.str-chat__link-preview-card {
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  .str-chat__tooltip {
    @include utils.ellipsis-text();
    display: block;
    max-width: calc(var(--str-chat__spacing-px) * 250);
    padding-inline: 0.5rem;
  }

  .str-chat__link-preview-card__icon-container {
    display: flex;
    align-items: center;
  }

  .str-chat__link-preview-card__content {
    width: 100%;
    min-width: 0;
    flex: 1;
    padding-inline: 0.5rem;

    .str-chat__link-preview-card__content-title,
    .str-chat__link-preview-card__content-description {
      @include utils.ellipsis-text();
    }
  }

  .str-chat__link-preview-card__dismiss-button {
    @include utils.button-reset;
    cursor: pointer;
  }
}