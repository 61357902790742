.str-chat {
  box-sizing: border-box;
  font-family: var(--str-chat__font-family);

  * {
    box-sizing: border-box;
  }

  // Overriding styles of ngx-popperjs/ngx-float-ui
  .ngxp__container,
  .float-ui-container {
    z-index: 1;
    padding: 0 !important;
    box-shadow: none !important;
    border-color: transparent !important;
    max-width: 100vw;

    .ngxp__arrow {
      display: none;
    }
  }
}

// Fixes icon sizing problem in Angular SDK
stream-icon,
stream-icon-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stream-chat__paginated-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 100%;
  min-height: 0;
  gap: var(--str-chat__spacing-2);

  .str-chat__loading-indicator {
    margin: auto;
  }

  .str-chat__load-more-button__button {
    margin-inline: auto;
  }
}

stream-paginated-list {
  height: 100%;
  max-height: 100%;
}

stream-user-list {
  height: 100%;
  max-height: 100%;

  .str-chat__user-list-item {
    display: flex;
    align-items: center;
    gap: var(--str-chat__spacing-2);
  }
}
