.str-chat__thread-list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.str-chat__unseen-threads-banner {
  font-size: 16px;
  font-weight: 400;

  margin: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button {
    --str-chat-icon-width: 16px;
    --str-chat-icon-heigh: 22px;

    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.str-chat__thread-list {
  .str-chat__thread-list-item {
    align-items: stretch;
    box-sizing: border-box;
    padding-block: 14px;
    padding-inline: 8px;
    gap: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .str-chat__thread-list-item__channel {
      --str-chat-icon-height: 14px;
      --str-chat-icon-width: 14px;

      display: flex;
      gap: 5px;

      .str-chat__icon {
        margin-top: 2px;
        flex-shrink: 0;
      }
    }

    .str-chat__thread-list-item__thread-status-symbol {
      font-size: 14px;
      line-height: 1;
    }

    .str-chat__thread-list-item__channel-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }

    .str-chat__thread-list-item__parent-message {
      display: flex;
      gap: 3px;
      justify-content: space-between;
      align-items: center;
      height: 15px;
    }

    .str-chat__thread-list-item__parent-message-text {
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .str-chat__thread-list-item__latest-reply {
      --str-chat__avatar-size: 49px;

      display: flex;
      align-items: center;
      gap: 5px;
    }

    .str-chat__thread-list-item__latest-reply-details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 4px;
      width: 0;
    }

    .str-chat__thread-list-item__latest-reply-created-by {
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .str-chat__thread-list-item__latest-reply-text-and-timestamp {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-between;
      align-items: baseline;
      line-height: 16px;
    }

    .str-chat__thread-list-item__latest-reply-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .str-chat__thread-list-item__latest-reply-timestamp {
      white-space: nowrap;
    }
  }

  .str-chat__thread-list-loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 14px;
    padding-inline: 8px;
  }
}

.str-chat__thread-list-empty-placeholder {
  --str-chat-icon-height: 95px;
  --str-chat-icon-width: 95px;

  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;
}
