@use 'variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: variables.$font-weight-bold;
  line-height: 1.2;
}

h1 {
  font-size: 40px;

  @media screen and (max-width: variables.$screen-md) {
    font-size: 36px;
  }

  @media screen and (max-width: variables.$screen-sm) {
    font-size: 32px;
  }
}

h2 {
  font-size: 32px;

  @media screen and (max-width: variables.$screen-md) {
    font-size: 28px;
  }

  @media screen and (max-width: variables.$screen-sm) {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;

  @media screen and (max-width: variables.$screen-md) {
    font-size: 24px;
  }

  @media screen and (max-width: variables.$screen-sm) {
    font-size: 20px;
  }
}

h4 {
  font-size: 24px;

  @media screen and (max-width: variables.$screen-md) {
    font-size: 22px;
  }

  @media screen and (max-width: variables.$screen-sm) {
    font-size: 20px;
  }
}

h5 {
  font-size: 20px;

  @media screen and (max-width: variables.$screen-md) {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
}

.ns-subtitle {
  line-height: 1.5;
  font-size: 16px;
  font-weight: variables.$font-weight-bold;

  &.small {
    font-size: 14px;
  }
}

.ns-text {
  line-height: 1.5;
  font-size: 16px;

  &.small {
    font-size: 14px;
  }

  &.extra-small {
    font-size: 12px;
  }
}
