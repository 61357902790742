@use 'variables';
@use 'mixins';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button {
  font-family: variables.$nunito;
  font-weight: variables.$font-weight-bold;
  // commented out for the sake of accessibility
  // outline: none;
  padding: 0;
  border: none;
  user-select: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

// TODO: remove all styles irrelevant to BEM convention once all buttons use BEM naming
.ns-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: variables.$font-weight-bold;
  min-height: 40px;
  padding: 0 variables.$button-side-padding;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition-duration: 0.3s;

  @media screen and (max-width: variables.$screen-sm) {
    font-size: 14px;
    min-height: 32px;
    border-radius: 16px;

    .ns-button__icon {
      @include mixins.icon-size(20px);

      &--left {
        margin-right: 6px;
      }

      &--right {
        margin-left: 6px;
      }
    }
  }

  &--loading {
    cursor: default;

    &.ns-button--small {
      .ns-button__spinner {
        $spinner-side: 18px;
        width: $spinner-side;
        height: $spinner-side;

        span {
          border-width: 2px;
        }
      }
    }

    .ns-button__spinner {
      opacity: 1;
      transform: scale(1);
      margin: 0 -10px 0 10px;
    }

    @media screen and (max-width: variables.$screen-sm) {
      .ns-button__spinner {
        transform: scale(0.75);
        margin: 0 -15px 0 5px;
      }
    }
  }

  &--small {
    font-size: 14px;
    min-height: 32px;
    border-radius: 16px;

    // @media screen and (max-width: variables.$screen-sm) {
    //   font-size: 12px;
    //   min-height: 28px;
    //   border-radius: 14px;
    // }

    .ns-button__icon {
      // font-size: 20px;
      @include mixins.icon-size(20px);

      // @media screen and (max-width: variables.$screen-sm) {
      //   font-size: 18px;
      // }

      &--left {
        margin-right: 6px;
      }

      &--right {
        margin-left: 6px;
      }
    }
  }

  // remove when all buttons use BEM naming
  &.ns-small {
    font-size: 14px;
    min-height: 32px;
    border-radius: 16px;

    @media screen and (max-width: variables.$screen-sm) {
      font-size: 12px;
      min-height: 28px;
      border-radius: 14px;
    }
  }

  &--dark,
  &.ns-dark {
    color: white;
    background-color: variables.$gray-7;

    &:not(:disabled, .ns-disabled, .ns-button--loading) {
      @media (hover: hover) {
        &:hover {
          background-color: variables.$gray-6;
        }
      }

      &:focus {
        box-shadow: variables.$box-shadow-button variables.$gray-7;
      }

      &:active {
        background-color: variables.$gray-8;
      }
    }

    &:disabled,
    &.ns-disabled {
      color: variables.$gray-2;
      background-color: variables.$gray-5;
    }
  }

  &--light,
  &.ns-light {
    color: variables.$gray-9;
    background-color: variables.$gray-2;

    &:not(:disabled, .ns-disabled, .ns-button--loading) {
      @media (hover: hover) {
        &:hover {
          background-color: variables.$gray-3;
        }
      }

      &:focus {
        box-shadow: variables.$box-shadow-button variables.$gray-2;
      }

      &:active {
        background-color: variables.$gray-4;
      }
    }

    &:disabled,
    &.ns-disabled {
      color: variables.$gray-5;
      background-color: variables.$gray-2;
    }
  }

  &--primary,
  &.ns-red {
    color: white;
    background-color: variables.$red-primary-5;

    &:not(:disabled, .ns-disabled, .ns-button--loading) {
      @media (hover: hover) {
        &:hover {
          background-color: variables.$red-primary-6;
        }
      }

      &:focus {
        box-shadow: variables.$box-shadow-button variables.$red-primary-5;
      }

      &:active {
        background-color: variables.$red-primary-7;
      }
    }

    &:disabled,
    &.ns-disabled {
      color: variables.$gray-5;
      background-color: variables.$gray-2;
    }
  }

  &--transparent,
  &.ns-transparent {
    color: variables.$gray-9;
    background-color: transparent;
    position: relative;

    &:after {
      content: '';
      height: 2px;
      width: calc(100% - #{variables.$button-side-padding} * 2);
      border-radius: 1px;
      background-color: transparent;
      transition-duration: inherit;
      pointer-events: none;
      position: absolute;
      left: variables.$button-side-padding;
      bottom: 8px;
      transform: scaleX(0.8) translateY(3px);

      @media screen and (max-width: variables.$screen-sm) {
        bottom: 5px;
      }
    }

    &.ns-button--small,
    &.ns-small {
      &:after {
        bottom: 5px;
      }
    }

    &.ns-button--text {
      min-height: unset;
      padding: 0;

      &:after {
        width: 100%;
        left: 0;
        bottom: 0;
        transform: scaleX(0.8) translateY(3px);
      }
    }

    &:hover,
    &:focus:not(.ns-disabled),
    &.active {
      &:after {
        transform: scaleX(1) translateY(0);
        background-color: variables.$gray-9;
      }
    }

    &:active {
      &:after {
        transform: scaleX(1.05);
      }
    }

    &:disabled,
    &.ns-disabled {
      color: variables.$gray-5;

      &:after {
        background-color: transparent;
      }
    }

    &.ns-light-text {
      color: white;

      &:hover,
      &.active {
        &:after {
          background-color: white;
        }
      }
    }

    .ns-button__icon {
      &--left {
        margin-right: 6px;
      }

      &--right {
        margin-left: 6px;
      }
    }
  }

  &:disabled,
  &.ns-disabled {
    cursor: not-allowed;
  }

  &__icon {
    &--left {
      margin-right: 8px;
    }

    &--right {
      margin-left: 8px;
    }
  }

  // remove when all buttons use BEM naming
  .ns-icon {
    &.ns-left {
      margin-right: 8px;
    }

    &.ns-right {
      margin-left: 8px;
    }
  }

  &__spinner {
    $spinner-side: 24px;
    width: $spinner-side;
    height: $spinner-side;
    position: relative;
    margin: 0 -#{$spinner-side} 0 0;
    opacity: 0;
    transform: scale(0);
    transition-duration: 0.2s;

    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: 3px solid transparent;

      &:first-child {
        border-color: white;
        opacity: 0.3;
      }

      &:last-child {
        border-top-color: white;
        animation: spin 0.7s infinite linear;
      }
    }
  }
}

// TODO: remove ↓ when all tabs use special component
.ns-tab-button {
  font-size: 16px;
  transition-duration: 0.3s;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  &.ns-light {
    color: variables.$gray-7;

    &:hover {
      background-color: variables.$gray-1;
    }

    &.ns-active-tab {
      color: variables.$gray-8;
      background-color: variables.$gray-2;
    }

    &.ns-active-tab-icon {
      @extend .ns-active-tab;

      span {
        margin-left: 34px;
      }

      &:before {
        font-family: 'Material Icons Round';
        content: '\e5ca';
        background: transparent;
        color: variables.$gray-6;
        position: absolute;
        font-size: 22px;
      }
    }
  }

  &.ns-dark {
    color: variables.$gray-7;

    &:hover {
      background-color: variables.$gray-3;
    }

    &.ns-active-tab {
      color: variables.$gray-1;
      background-color: variables.$gray-7;
    }
  }

  &.ns-red {
    color: variables.$gray-7;

    &:hover {
      background-color: variables.$red-primary-1;
    }

    &.ns-active-tab {
      color: variables.$gray-1;
      background-color: variables.$red-primary-5;
    }
  }

  span {
    transition-duration: 0.3s;
  }
}

.ns-table-tabs {
  button {
    @extend .ns-tab-button;
    padding: 0;
  }
}

.ns-tabs-old {
  button {
    @extend .ns-tab-button;
  }

  &.ns-horizontal {
    &:not(.ns-transparent) {
      button {
        padding: 10px 32px;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &.ns-transparent {
      button {
        margin: 0 -4px;

        &:first-child {
          margin-left: -(variables.$button-side-padding);
        }

        &:last-child {
          margin-right: -(variables.$button-side-padding);
        }
      }
    }
  }

  &.ns-vertical {
    &:not(.ns-transparent) {
      button {
        text-align: left;
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.ns-transparent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-bottom: 16px;
        margin-left: -(variables.$button-side-padding);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.ns-dark {
    button {
      @extend .ns-dark;
    }
  }

  &.ns-light {
    button {
      @extend .ns-light;
    }
  }

  &.ns-red {
    button {
      @extend .ns-red;
    }
  }

  &.ns-transparent {
    button {
      @extend .ns-button;
      @extend .ns-transparent;
    }

    &.ns-light-text {
      button {
        @extend .ns-light-text;
      }
    }
  }
}
// remove ↑ when all tabs use special component
