@use 'variables';

$z-sticky: 1;
$scroll-shadow-size: 8px;

.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--overflowing-to-the-left {
    .sticky {
      &.left {
        border-color: transparent;

        &:before {
          right: -$scroll-shadow-size;
        }
      }
    }
  }

  &--overflowing-to-the-right {
    .sticky {
      &.right {
        &:before {
          left: -$scroll-shadow-size;
        }
      }
    }
  }
}

.mat-mdc-header-row {
  position: sticky;
  top: 0;
  z-index: $z-sticky + 1;
  display: inline-flex;
  min-width: 100%;
  min-height: 40px;
  border: none;
  background-color: variables.$gray-2 !important;
  user-select: none;
}

.mat-mdc-row {
  display: inline-flex;
  align-items: stretch;
  min-width: 100%;
  min-height: 48px;
  max-height: 120px;
  border: none;

  @media screen and (max-width: variables.$screen-sm) {
    max-height: 96px;
  }

  &:nth-of-type(odd) {
    background-color: variables.$gray-1 !important;
  }

  &:nth-of-type(even) {
    background-color: white !important;
  }
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  flex-basis: auto;
  overflow: unset !important;
  border-bottom: none !important;

  &:not(:last-of-type) {
    border-right: 1px solid variables.$gray-1;
  }
}

.mat-mdc-header-cell {
  padding: 2px 14px !important;
  color: variables.$gray-6;
  font-size: 12px;
  line-height: 1.5 !important;
  font-weight: 700 !important;
  transition-duration: 0.2s;

  &.mat-sort-header:not(.mat-sort-header-disabled) {
    cursor: pointer;
  }

  &.mat-sort-header.cdk-keyboard-focused,
  &.mat-sort-header.cdk-program-focused {
    background-color: variables.$gray-3;

    .mat-sort-header-container {
      border-bottom: none;
    }
  }

  .ns-tooltip-trigger {
    display: flex;
    color: variables.$gray-4;
    margin-left: 4px;
    cursor: default;

    i, mat-icon {
      height: auto;
      width: auto;
      font-size: 16px;
    }
  }
}

.mat-mdc-cell {
  font-size: 14px;
  line-height: 1.5 !important;
  color: variables.$gray-9;
  padding: 12px 14px !important;

  &.center {
    justify-content: center;

    .ns-clickable-cell-button {
      justify-content: inherit;
    }
  }

  &.right {
    justify-content: flex-end;
  }

  &.clickable {
    &:not(.sticky) {
      position: relative;
    }

    > * {
      letter-spacing: inherit;

      &:not(mat-menu) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  .ns-clickable-cell-button {
    display: flex;
    align-items: center;
    color: inherit;
    font-weight: inherit;
    background-color: transparent;
    padding: 12px 16px;
    outline: none;
    cursor: pointer;
    user-select: none;
    transition-duration: 0.2s;

    &:not(:disabled) {
      @media (hover: hover) {
        &:hover,
        &:focus {
          background-color: variables.$gray-2;
        }
      }

      &:active {
        background-color: variables.$gray-3;
      }
    }

    &:disabled {
      cursor: default;
    }

    .ns-scrollable-cell-wrapper {
      max-height: 100%;
    }
  }

  .ns-user-cell {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    color: variables.$gray-9;
    background-color: transparent;
    padding: 12px 16px;
    outline: none;
    white-space: nowrap;
    text-align: left;
    transition-duration: 0.2s;

    &:not(:disabled, .disabled) {
      &:hover,
      &:focus {
        background-color: variables.$gray-2;
      }

      &:active {
        background-color: variables.$gray-3;
      }
    }

    &:disabled,
    &.disabled {
      cursor: default;
    }

    .ns-avatar-wrapper {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      font-size: 20px;
      position: relative;
      margin-right: 16px;

      app-user-activation-status {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
      }
    }

    .ns-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .reminder-cell {
    background-color: transparent;
  }

  .ns-scrollable-cell-wrapper {
    max-height: 100%;
    overflow: auto;
    overflow-wrap: anywhere;

    &.clickable {
      $text-padding: 4px;

      width: calc(100% + #{$text-padding} * 2);
      height: 100%;
      padding: 0 $text-padding;
      color: inherit;
      background-color: transparent;
      font: inherit;
      display: flex;
      align-items: center;
      border-radius: variables.$radius-small;
      text-align: left;
      outline: none;
      transition-duration: 0.2s;

      &:hover,
      &:focus {
        background-color: variables.$gray-2;
      }

      &:active {
        background-color: variables.$gray-3;
      }

      p {
        margin: auto 0;
      }
    }
  }

  .ns-payment-status-cell p {
    color: variables.$gray-8;

    &.ns-paid {
      color: variables.$green-success-5;
    }

    &.ns-not-paid {
      color: variables.$red-error-5;
    }

    &.ns-upcoming {
      color: variables.$yellow-warning-6;
    }
  }

  .ns-not-paid {
    color: variables.$red-error-5;
  }

  .ns-upcoming {
    color: variables.$yellow-warning-6;
  }

  .ns-ongoing {
    color: variables.$green-success-5;
    font-weight: variables.$font-weight-bold;
  }

  .ns-on-hold {
    color: variables.$gray-5;
  }

  &.text-with-icon {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .ns-progress-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ns-progress-bar {
      height: 16px;
      background-color: variables.$gray-2;
      border-radius: variables.$radius-small;
      overflow: hidden;

      .ns-progress {
        display: flex;
        height: 100%;

        &.good {
          background-color: variables.$green-success-4;
        }

        &.ok {
          background-color: variables.$yellow-warning-4;
        }

        &.bad {
          background-color: variables.$red-error-3;
        }

        &.hold {
          background-color: variables.$gray-5;
        }
      }
    }

    .ns-progress-numbers {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ns-percentage {
        font-weight: variables.$font-weight-bold;
      }
    }
  }
}

.table-footer {
  min-height: 40px;
  min-width: 100%;
  padding: 8px 14px;
  flex-shrink: 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px 16px;
  color: variables.$gray-6;
  background-color: variables.$gray-2;
  font-size: 12px;
  line-height: 1.5;
  font-weight: variables.$font-weight-bold;
  user-select: none;
}

.sticky {
  position: sticky;
  z-index: $z-sticky;

  &.left {
    left: 0;

    &:before {
      left: 100%;
      right: 0;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
    }
  }

  &.right {
    right: 0;

    &:before {
      left: 0;
      right: 100%;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), transparent);
    }
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    transition-duration: 0.2s;
  }
}

.mat-sort-header-container {
  flex-grow: 1;
}

.mat-sort-header-content {
  text-align: left !important;
  flex-grow: 1;
}

.mat-column-actions {
  align-items: stretch;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  text-align: center;

  button {
    background-color: transparent;
    outline: none;
  }

  .ns-kebab-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$gray-5;
    transition-duration: 0.2s;

    &:not(:disabled, .disabled) {
      &:hover,
      &:focus {
        background-color: variables.$gray-2;
      }
    }

    &:disabled,
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .ns-arrow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$gray-4;
    transition-duration: 0.2s;
    user-select: none;

    &:hover,
    &:focus {
      i, mat-icon {
        transform: translateX(3px);
      }
    }

    &:focus {
      background-color: variables.$gray-2;
    }

    &.red {
      color: variables.$red-error-4;
    }

    &.black {
      color: variables.$gray-8;
    }

    i, mat-icon {
      transition-duration: inherit;
    }
  }
}

.mat-column-bulk-action {
  justify-content: center;
  align-items: center;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  padding: 0 !important;

  .mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 30px;
    overflow: hidden;
  }
}

.ns-table-empty-state {
  height: 72px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$gray-7;
  font-weight: variables.$font-weight-bold;
  background-color: white;
  user-select: none;
}

.ns-paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  padding: 0 16px;
  min-height: 40px;
  color: variables.$gray-6;
  background-color: variables.$gray-2;
  font-size: 12px;
  font-weight: variables.$font-weight-bold;
  user-select: none;

  > * {
    position: relative;
    z-index: 101;
  }

  &__buttons {
    display: flex;
    gap: 16px;
  }

  &__button {
    display: flex;
    color: inherit;
    background-color: transparent;
    padding: 5px;
    border-radius: 50%;
    outline: none;
    transition-duration: 0.2s;

    &:not(:disabled) {
      @media (hover: hover), (pointer: fine) {
        &:hover,
        &:focus {
          background-color: variables.$gray-3;
        }
      }

      &:first-of-type {
        &:active {
          i, mat-icon {
            transform: scale(0.9) translateX(-5px) rotateY(45deg);
          }
        }
      }

      &:last-of-type {
        &:active {
          i, mat-icon {
            transform: scale(0.9) translateX(5px) rotateY(-45deg);
          }
        }
      }
    }

    &:disabled {
      color: variables.$gray-3;
    }

    i, mat-icon {
      transition-duration: 0.15s;
    }
  }
}
