.str-chat__edit-message-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  row-gap: var(--str-chat__spacing-5);
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;

  .str-chat__edit-message-form-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      cursor: pointer;
      margin: 0;
    }
  }
}

.str-chat-angular__edit-message-form {
  .str-chat__message-input-angular-host {
    max-height: 100%;
    min-height: 0;
    display: flex;
    min-width: 0;
    max-width: 100%;
  }

  .str-chat__modal--open {
    .str-chat__modal__inner {
      height: 40%;
      max-height: 80%;
      min-width: 90%;
      max-width: 90%;
      width: 90%;
      flex-basis: min-content;

      @media only screen and (min-device-width: 768px) {
        min-width: 40%;
        max-width: 60%;
        width: min-content;
      }
    }
  }
}
