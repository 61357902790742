.str-chat__message-bounce-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--str-chat__spacing-9);

  .str-chat__message-bounce-actions {
    display: flex;
    flex-direction: column;
    gap: var(--str-chat__spacing-2);
  }

  .str-chat__message-bounce-edit,
  .str-chat__message-bounce-send,
  .str-chat__message-bounce-delete {
    cursor: pointer;
    padding: var(--str-chat__spacing-2);
  }
}
