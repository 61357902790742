.str-chat {
  /* The size of the loading indicator, only available in Angular v5+ */
  --str-chat__loading-indicator-size: calc(var(--str-chat__spacing-px) * 15);
}

.str-chat__loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 1s linear infinite;

  svg {
    width: var(--str-chat__loading-indicator-size);
    height: var(--str-chat__loading-indicator-size);
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

stream-loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  .str-chat__loading-indicator {
    width: var(--str-chat__loading-indicator-size);
    height: var(--str-chat__loading-indicator-size);
  }
}

.str-chat__loading-indicator-placeholder {
  width: var(--str-chat__loading-indicator-size);
  height: var(--str-chat__loading-indicator-size);
}
