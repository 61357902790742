@use '../utils';

// FIXME: figure out why does this "container" exist when __thread is completely valid
.str-chat__thread-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  .str-chat__thread-header {
    @include utils.header-layout;

    .str-chat__thread-header-details {
      @include utils.header-text-layout;

      .str-chat__thread-header-name,
      .str-chat__thread-header-reply-count,
      .str-chat__thread-header-channel-name,
      .str-chat__thread-header-subtitle,
      .str-chat__thread-header-title {
        @include utils.ellipsis-text;
      }

      .str-chat__thread-header-subtitle {
        @include utils.prevent-glitch-text-overflow;
      }
    }

    .str-chat__close-thread-button {
      $icon-size: calc(var(--str-chat__spacing-px) * 21);
      @include utils.flex-top-right;
      width: calc(var(--str-chat__spacing-px) * 40);
      height: calc(var(--str-chat__spacing-px) * 40);
      cursor: pointer;
      line-height: $icon-size;
      font-size: $icon-size;

      svg {
        height: $icon-size;
        width: $icon-size;
      }
    }
  }
}

.str-chat__thread {
  .str-chat__main-panel-inner {
    height: auto;
  }
}

.str-chat__thread--virtualized {
  .str-chat__main-panel-inner {
    height: 100%;

    // the first message in virtualized thread has to be separated from the top by padding, not margin
    .str-chat__virtual-list-message-wrapper:first-of-type {
      padding-block-start: var(--str-chat__spacing-4);
    }
  }
}

.str-chat__parent-message-li {
  padding: var(--str-chat__spacing-2);
}
