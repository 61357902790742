.str-chat__chat-view {
  display: flex;
  width: 100%;
  height: 100%;

  &__selector {
    display: flex;
    flex-direction: column;
    padding-inline: 8px;
    padding-block: 16px;
    gap: 20px;

    &-button {
      --str-chat-icon-height: 20px;
      --str-chat-icon-width: 20px;
      --str-chat-unread-count-badge-absolute-offset-vertical: 25%;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding-inline: 10px;
      padding-block: 10px;
      border-radius: 8px;
      gap: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
    }
  }

  &__channels {
    display: flex;
    flex-grow: 1;
  }

  &__threads {
    display: flex;
    flex-grow: 1;
  }
}
