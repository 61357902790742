@use '../utils';

.str-chat__channel-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .str-chat__channel-list-messenger {
    height: 100%;

    .str-chat__channel-list-messenger__main {
      height: 100%;

      .str-chat__channel-list-empty {
        --str-chat-icon-height: calc(var(--str-chat__spacing-px) * 136);
        @include utils.empty-layout;
      }

      // Empty channel list for theme-v1
      .str-chat__channel-list-empty-v1 {
        display: none;
      }
    }
  }

  .str-chat__load-more-button {
    display: flex;
    justify-content: center;
    margin: var(--str-chat__spacing-2) 0;

    .str-chat__load-more-button__button {
      @include utils.flex-row-center;
    }
  }

  .stream-chat__paginated-list {
    gap: 0;
  }
}

.str-chat__channel-list-react {
  overflow: hidden;

  .str-chat__channel-list-messenger-react {
    overflow: hidden;
    padding-bottom: var(--str-chat__spacing-2_5);

    .str-chat__channel-list-messenger-react__main {
      overflow-y: auto;
    }
  }
}

.str-chat-angular__channel-list {
  .str-chat__channel-list-messenger__main {
    display: flex;
    flex-direction: column;

    stream-paginated-list {
      min-height: 0;
    }
  }
}
