@font-face {
  font-family: "icons";
  src: url("/assets/fonts/icons.eot");
  src: url("/assets/fonts/icons.eot?#iefix") format("eot"),
    url("/assets/fonts/icons.woff2") format("woff2"),
    url("/assets/fonts/icons.woff") format("woff"),
    url("/assets/fonts/icons.ttf") format("truetype"),
    url("/assets/fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lg {
  font-size: 1.5em;
  line-height: .75rem;
  vertical-align: -35%;
}

.icon-btn {
  font-size: 1.2em;
  line-height: .75rem;
  vertical-align: -45%;
}

.icon-lg-btn {
  font-size: 1.7em;
  line-height: .75rem;
  vertical-align: -45%;
}

.icon-1_75x {
  font-size: 1.75rem;
}

.icon-30 {
  font-size: 1.875rem;
}

.icon-2x {
  font-size: 2rem;
}

.icon-40 {
  font-size: 2.5rem;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.icon-crowns {
  &:before {
    content: "\EA01"
  }
}

.icon-football {
  &:before {
    content: "\EA02"
  }
}

.icon-forum-unread {
  &:before {
    content: "\EA03"
  }
}

.icon-hashtag {
  &:before {
    content: "\EA04"
  }
}

.icon-loader {
  &:before {
    content: "\EA05"
  }
}

.icon-no-email {
  &:before {
    content: "\EA06"
  }
}

.icon-note {
  &:before {
    content: "\EA07"
  }
}

.icon-percent-filled {
  &:before {
    content: "\EA08"
  }
}

.icon-percent {
  &:before {
    content: "\EA09"
  }
}

.icon-t-shirt {
  &:before {
    content: "\EA0A"
  }
}

.icon-trophy {
  &:before {
    content: "\EA0B"
  }
}

.icon-user-star {
  &:before {
    content: "\EA0C"
  }
}

.icon-warning-amber {
  &:before {
    content: "\EA0D"
  }
}
