// Since Angular uses dynamic components it's not enough the hide the message input for theme-v1/v2, we have to remove it from the DOM tree
// Angular component lifecycle requires us to place this on the root level
:root {
  /* The theme version being used, 1 or 2. Used internally by SDKs */
  --str-chat__theme-version: 2;
}

.str-chat {
  /* Used for elements where sizing is necessary (such as icons, avatar), you can use this variable to scale those elements */
  --str-chat__spacing-px: 1px;

  /* Used for margins and paddings */
  --str-chat__spacing-0_5: 0.125rem;

  /* Used for margins and paddings */
  --str-chat__spacing-1: 0.25rem;

  /* Used for margins and paddings */
  --str-chat__spacing-1_5: 0.375rem;

  /* Used for margins and paddings */
  --str-chat__spacing-2: 0.5rem;

  /* Used for margins and paddings */
  --str-chat__spacing-2_5: 0.625rem;

  /* Used for margins and paddings */
  --str-chat__spacing-3: 0.75rem;

  /* Used for margins and paddings */
  --str-chat__spacing-3_5: 0.875rem;

  /* Used for margins and paddings */
  --str-chat__spacing-4: 1rem; // 16px by default

  /* Used for margins and paddings */
  --str-chat__spacing-5: 1.25rem;

  /* Used for margins and paddings */
  --str-chat__spacing-6: 1.5rem;

  /* Used for margins and paddings */
  --str-chat__spacing-7: 1.75rem;

  /* Used for margins and paddings */
  --str-chat__spacing-8: 2rem;

  /* Used for margins and paddings */
  --str-chat__spacing-9: 2.25rem;

  /* Used for margins and paddings */
  --str-chat__spacing-10: 2.5rem;

  /* Used for margins and paddings */
  --str-chat__spacing-11: 2.75rem;

  /* Used for margins and paddings */
  --str-chat__spacing-12: 3rem;

  /* Used for margins and paddings */
  --str-chat__spacing-14: 3.5rem;

  /* Used for margins and paddings */
  --str-chat__spacing-16: 4rem;
}
