.str-chat__image-carousel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;

  .str-chat__image-carousel-stepper {
    --str-chat-icon-height: calc(var(--str-chat__spacing-px) * 30);
    padding: var(--str-chat__spacing-2_5);
    border: none;
    cursor: pointer;
    background: transparent;
  }

  .str-chat__image-carousel-image {
    object-fit: contain;
    min-height: 0;
    max-height: 100%;
    min-width: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
}

.str-chat__message {
  .str-chat__attachment-list {
    .str-chat__modal--open {
      .str-chat__modal__inner {
        height: 80%;
        width: 80%;

        @media only screen and (max-device-width: 768px) {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

[dir='rtl'] .str-chat__image-carousel-stepper {
  svg {
    transform: scale(-1, 1);
  }
}
