@use 'variables';

.ng-select {
  padding: 0;

  &.ng-select-single .ng-select-container .ng-value-container {
    .ng-value {
      padding: 0 !important;
      background-color: transparent !important;

      .ng-value-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ng-input {
      bottom: unset;
      left: unset !important;
      width: auto !important;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container {
    .ng-value {
      white-space: pre-wrap !important;
    }
  }

  &.ng-select-focused,
  &.ng-select-opened {
    &:not(.ng-invalid.ng-touched) .ng-select-container.ng-appearance-outline:after {
      border-color: variables.$gray-8 !important;
    }
  }

  &.ng-invalid.ng-touched .ng-select-container.ng-appearance-outline {
    &:after {
      border-color: variables.$red-error-5;
    }

    .ng-value-container .ng-placeholder {
      color: variables.$red-error-5;
    }
  }

  &.ng-select-opened .ng-select-container .ng-value-container,
  &.ng-select-focused .ng-select-container .ng-value-container,
  .ng-has-value {
    .ng-placeholder {
      transform: translateY(-26px) scale(0.75) perspective(100px) translateZ(0.001px);
      color: variables.$gray-10;
    }
  }

  &.ng-select-disabled .ng-select-container.ng-appearance-outline:after {
    border-color: rgba(0, 0, 0, 0.06) !important;
    background: none;
  }

  .ng-select-container {
    overflow: unset !important;

    &.ng-appearance-outline {
      min-height: 56px;
      align-items: center;
      padding: 0 16px;

      &:after {
        height: calc(100% - 4px);
        border: 2px solid variables.$gray-3;
        border-radius: variables.$radius-small;
      }

      &:hover {
        &:after {
          border-color: variables.$gray-5;
        }
      }
    }

    .ng-value-container {
      gap: 8px;
      padding: 12px 0 !important;
      border: none;
      align-self: stretch;
      align-items: center;

      .ng-placeholder {
        align-self: flex-start;
        color: variables.$gray-10;
        margin-top: 8px;
        padding: 0 4px;
        margin-left: -4px;
        user-select: none;
      }

      .ng-value {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 4px 6px !important;
        line-height: 21px;
        margin: 0 !important;
        color: variables.$gray-8 !important;
        background-color: variables.$gray-1 !important;
        border-radius: variables.$radius-small !important;

        .ng-value-label {
          word-break: break-all;
        }

        .ng-value-icon {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 28px;
          color: variables.$gray-4 !important;
          padding: 0 !important;
          margin-left: 6px;
          transition-duration: 0.2s;

          &:hover {
            color: variables.$gray-5 !important;
          }
        }
      }

      .ng-input {
        > input {
          font-family: variables.$nunito;
          color: variables.$gray-8;
          font-size: 16px;
        }
      }
    }

    .ng-clear-wrapper {
      width: 48px;
      align-self: stretch !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      color: variables.$gray-5;
      margin: 8px 0 8px 12px;
      padding: 0 12px;
      border-right: 2px solid variables.$gray-3;
      transition-duration: 0.2s;
      top: 0 !important;

      &:hover {
        color: variables.$gray-6 !important;
      }

      .ng-clear {
        font-size: inherit;
      }
    }
  }

  .ng-arrow-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -4px 0 12px;
    cursor: default !important;
    position: static !important;
    align-self: unset !important;

    .ng-arrow {
      border-bottom: 5px solid transparent;
      margin: 5px 0 0 0;
    }
  }

  .ng-dropdown-panel {
    height: fit-content;
    top: 100%;
    border-radius: variables.$radius-small;

    .ng-dropdown-panel-items {
      border-radius: inherit;

      .ng-option {
        color: variables.$gray-8;
        line-height: 1.5;
        min-height: 48px;
        padding: 12px 16px;
        white-space: normal;

        &:hover {
          background-color: variables.$gray-1;
        }

        .clickable-disabled {
          color: variables.$gray-5;
        }
      }
    }
  }
}
