/*----------------------------------------

  KORDE - MIXINS

-----------------------------------------*/

// Transitions
// ----------------------------

@mixin trans($type: all, $time: 300ms) {
  -webkit-transition: $type $time ease;
  -moz-transition: $type $time ease;
  -ms-transition: $type $time ease;
  -o-transition: $type $time ease;
  transition: $type $time ease;
}

// Gradients
// ----------------------------

@mixin grad-down($first, $second) {
  background: $first; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient($first, $second); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($first, $second); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($first, $second); /* For Firefox 3.6 to 15 */
  background: linear-gradient($first, $second); /* Standard syntax */
}

@mixin grad-side($first, $second) {
  background: $first; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, $first , $second); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, $first, $second); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, $first, $second); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, $first , $second); /* Standard syntax */
}

// Shapes
// ----------------------------
@mixin circle($diameter) {
  width: $diameter;
  height: $diameter;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

@mixin square($size) {
  width: $size;
  height: $size;
}