@use 'variables';

.owl-dialog-container {
  overflow: initial;
}

.owl-dt-dialog-container {
  overflow: hidden auto;
}

.owl-dt-trigger-disabled {
  opacity: 1;
}

.owl-dt-container-buttons {
  color: variables.$gray-7;

  .owl-dt-container-control-button:last-child {
    color: white;
    background-color: variables.$red-primary-5;
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: variables.$red-primary-5;
  font-weight: variables.$font-weight-bold;
}
