@use '../utils';

.str-chat__message-reactions-container {
  display: flex;

  .str-chat__message-reactions {
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    list-style: none;
    display: flex;
    margin-block-start: var(--str-chat__spacing-0_5);
    margin-block-end: var(--str-chat__spacing-0_5);
    column-gap: var(--str-chat__spacing-0_5);
    width: fit-content;
    padding: 0;
    position: relative;

    .str-chat__message-reaction {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--str-chat__spacing-1_5);
      position: relative;
      cursor: pointer;
      flex-shrink: 0;

      button {
        @include utils.button-reset;
        @include utils.flex-row-center;
        padding: 0;
      }

      .str-chat__message-reaction-emoji {
        height: calc(var(--str-chat__spacing-px) * 13);
        display: flex !important; // Overriding emoji-mart styles
        align-items: center;
        justify-content: center;
      }
    }

    // Only in theme-v1
    .str-chat__reaction-list--counter,
    .str-chat__simple-reactions-list-item--last-number {
      display: none;
    }
  }
}

.str-chat__message--me {
  .str-chat__message-reactions-container {
    justify-content: flex-end;
  }
}

.str-chat__message--other {
  .str-chat__message-reactions-container {
    justify-content: flex-start;
  }
}

.str-chat__message-reactions-details-modal {
  .str-chat__modal--open {
    .str-chat__modal__inner {
      height: 40%;
      max-height: 80%;
      min-width: 90%;
      max-width: 90%;
      width: 90%;
      flex-basis: min-content;

      @media only screen and (min-device-width: 768px) {
        min-width: 40%;
        max-width: 60%;
        width: min-content;
      }
    }
  }
}

.str-chat__message-reactions-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--str-chat__spacing-4);
  max-height: 100%;
  height: 100%;
  min-height: 0;

  .str-chat__message-reactions-details-reaction-types {
    display: flex;
    max-width: 100%;
    width: 100%;
    min-width: 0;
    overflow-x: auto;
    gap: var(--str-chat__spacing-4);
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .str-chat__message-reactions-details-reaction-type {
      display: flex;
      align-items: center;
      padding: var(--str-chat__spacing-1) 0;
      flex-shrink: 0;
      cursor: pointer;

      .str-chat__message-reaction-emoji--with-fallback {
        width: 18px;
        line-height: 18px;
      }
    }
  }

  .str-chat__message-reaction-emoji-big {
    --str-chat__stream-emoji-size: 1em;
    align-self: center;
    font-size: 2rem;
  }

  .str-chat__message-reaction-emoji-big.str-chat__message-reaction-emoji--with-fallback {
    line-height: 2rem;
  }

  .str-chat__message-reactions-details-reacting-users {
    display: flex;
    flex-direction: column;
    gap: var(--str-chat__spacing-3);
    max-height: 100%;
    overflow-y: auto;
    min-height: 30vh;

    .str-chat__loading-indicator {
      margin: auto;
    }

    .str-chat__message-reactions-details-reacting-user {
      display: flex;
      align-items: center;
      gap: var(--str-chat__spacing-2);
    }
  }
}
