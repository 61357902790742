@use 'variables';

.ns-checkbox-filters {
  border-top: 1px solid variables.$gray-2;
  border-bottom: 1px solid variables.$gray-2;
  margin: 0 -#{variables.$modal-side-padding} 24px -#{variables.$modal-side-padding};
}

.ns-checkbox-filter {
  &:not(:first-child) {
    border-top: 1px solid variables.$gray-2;
  }

  mat-checkbox {
    --mat-checkbox-label-text-color: #{variables.$gray-9};
    --mat-checkbox-label-text-size: 16px;
    --mat-checkbox-label-text-line-height: 1.5;

    display: block;

    .mdc-form-field {
      display: flex;
      position: relative;
    }

    label {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 72px 12px variables.$modal-side-padding;
      transition-duration: 0.2s;
      cursor: pointer;

      &:hover {
        background-color: variables.$gray-1;
      }
    }

    .mdc-checkbox {
      position: absolute;
      right: variables.$modal-side-padding;
    }
  }
}

.ns-expandable-filters {
  border-bottom: 1px solid variables.$gray-2;
  margin: 0 -#{variables.$modal-side-padding} 32px;

  @media screen and (max-width: variables.$screen-xs) {
    margin-inline: -#{variables.$popup-side-padding-mobile};
  }
}

.ns-expandable-filter {
  border-top: 1px solid variables.$gray-2;

  &__button {
    width: 100%;
    display: flex;
    padding: 12px variables.$modal-side-padding;
    background-color: white;
    font-weight: normal;
    outline: none;
    transition-duration: 0.2s;

    @media screen and (max-width: variables.$screen-xs) {
      padding-inline: variables.$popup-side-padding-mobile;
    }

    @media (hover: hover) {
      &:hover,
      &:focus {
        background-color: variables.$gray-1;
      }
    }

    @media (hover: none) {
      &:active {
        background-color: variables.$gray-1;
      }
    }
  }

  &__label {
    flex-grow: 1;
    color: variables.$gray-9;
    text-align: left;
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    color: variables.$gray-5;

    i, mat-icon {
      position: absolute;
      opacity: 0;
      transform: scale(0);
      transition-duration: 0.3s;

      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  app-expand-y {
    padding: 0 24px;

    .ns-inline-fields {
      padding: 22px 0 28px;
    }
  }
}

.chips {
  &--light {
    .chip {
      color: variables.$gray-7;
      background-color: white;

      button {
        color: variables.$gray-4;

        &:hover,
        &:focus {
          background-color: variables.$gray-1;
        }

        &:active {
          background-color: variables.$gray-2;
        }
      }
    }
  }

  &--dark {
    .chip {
      color: white;
      background-color: variables.$gray-7;

      button {
        color: white;

        &:hover,
        &:focus {
          background-color: variables.$gray-6;
        }

        &:active {
          background-color: variables.$gray-5;
        }
      }
    }
  }

  &--gray {
    .chip {
      color: variables.$gray-7;
      background-color: variables.$gray-2;

      button {
        color: variables.$gray-4;

        &:hover,
        &:focus {
          background-color: variables.$gray-3;
        }

        &:active {
          color: white;
          background-color: variables.$gray-4;
        }
      }
    }
  }

  .chip {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 14px;
    border-radius: 18px;
    user-select: none;
    word-break: break-all;

    button {
      display: inline-flex;
      background-color: transparent;
      outline: none;
      padding: 2px;
      margin: -4px -2px -4px 6px;
      border-radius: 50%;
      transition-duration: 0.2s;
    }
  }
}
