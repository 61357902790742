/*----------------------------------------

  KORDE - MAIN

-----------------------------------------*/

@import "variables",
        "mixins",
        "placeholders",
        "buttons",
        "form",
        "textstyles",
        "properties",
        "components",
        "nav",
        "grid",
        "list";

// General fixes

body {
// // Default text
  font-size: $defaultTextSize;
//   color: $defaultTextColor;

//   @media (max-width: $smallMediaSize) {
//     font-size: $defaultTextSize * 0.7;
//   }
}
