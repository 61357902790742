.str-chat__message-actions-box {
  overflow: hidden; // Avoids message action box item background overflow in hovered state

  .str-chat__message-actions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-width: 10rem;

    .str-chat__message-actions-list-item-button {
      padding: var(--str-chat__spacing-2) var(--str-chat__spacing-4);
      margin: 0;
      cursor: pointer;
      width: 100%;
      text-align: start;
    }
  }
}
