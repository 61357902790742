@use 'variables';

@mixin screen-sm {
  @media screen and (min-width: variables.$screen-sm) {
    @content;
  }
}

@mixin screen-md {
  @media screen and (min-width: variables.$screen-md) {
    @content;
  }
}

@mixin screen-lg {
  @media screen and (min-width: variables.$screen-lg) {
    @content;
  }
}

@mixin nunito {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin multiline-ellipsis($quantity) {
  display: -webkit-box;
  -webkit-line-clamp: $quantity;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin scrollbar-theme($track-color, $thumb-color) {
  --scrollbar-track-color: #{$track-color};
  --scrollbar-thumb-color: #{$thumb-color};

  // Don't use scrollbar-color when a more customizable ::-webkit-scrollbar properties are supported
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: $thumb-color $track-color;
  }
}

@mixin column-size($size) {
  width: #{$size}px;
  flex-grow: $size;
}

@mixin unstick($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    position: relative !important;
    border-color: variables.$gray-1 !important;

    &:before {
      opacity: 0;
    }
  }
}

@mixin icon-size($size) {
  font-size: $size;
  width: $size;
  height: $size;
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
