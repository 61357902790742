/*----------------------------------------
  KORDE - VARIABLES
-----------------------------------------*/
// Colors
// -------------------
// Main colors
// Main colors
$light: #fff !default;
$stable-light: rgb(242, 242, 240) !default;
$stable: #d7d9da !default;
$stable-dark: #666 !default;
$primary: #f35862 !default;
$primary-dark: #f35862 !default;
$green: #44c18b !default;
$blue: #4c7c96 !default;
$aqua: #00FFFF !default;
$pink: #fc7fce !default;
$decline: #c0392b !default;
$orange: #DF7F3A !default;
$accept: #27ae60 !default;
$dark: rgb(55, 55, 55) !default;
$black: #000 !default;

$gray_dark: #4A4A4A !default;
$gray: #9B9B9B !default;
$gray_light: #C4C4C4 !default;
$gray_extra_light: #E5E5E5 !default;
$gray_ultra_light: #F2F2F2 !default;
$off_white: #FCFCFC !default;
$green_dark: #03AD72 !default;
$green_medium: #12C486 !default;
$green_light: #23D999 !default;
$sky_dark: #2185B0 !default;
$sky_medium: #1F9ACF !default;
$sky_light: #46BFF2 !default;
$wine_dark: #D43376 !default;
$wine_medium: #DB3B7E !default;
$wine_light: #F54991 !default;
$yellow_dark: #F29807 !default;
$yellow_medium: #F79E0F !default;
$yellow_light: #FFC05C !default;
$purple_dark: #6E30D9 !default;
$purple_medium: #7638E0 !default;
$purple_light: #935BF5 !default;
$lagoon_dark: #04BFB0 !default;
$lagoon_medium: #08CCBC !default;
$lagoon_light: #18DBCB !default;
$navy_dark: #2469B3 !default;
$navy_medium: #2A6DB5 !default;
$navy_light: #478CD6 !default;
$red_dark: #ED343A !default;
$red_medium: #F7484E !default;
$red_light: #FF696E !default;
$hoopit: #f35862 !default;
$success: #3DD68B !default;
$warning: #FFE13D !default;
$error:	#F44336 !default;

// Text
// -------------------
$defaultFont: 'Rubik', Arial, Helvetica, sans-serif;

$defaultTextColor: #333;
$defaultTextSize: 12px;
// List of main colors
$mainColors: (
    ('light', $light, $defaultTextColor, darken($light, 10%), darken($light, 20%)),
    ('stable-light', $stable-light, $dark, darken($stable-light, 10%), darken($stable-light, 20%)),
    ('stable', $stable, lighten($dark, 10%), darken($stable, 10%), darken($stable, 20%)),
    ('stable-dark', $stable-dark, $light, lighten($stable-dark, 10%), lighten($stable-dark, 20%)),
    ('primary', $primary, $light, lighten($primary, 10%), lighten($primary, 20%)),
    ('primary-dark', $primary-dark, $light, lighten($primary-dark, 10%), lighten($primary-dark, 20%)),
    ('green', $green, $light, lighten($green, 10%), lighten($green, 20%)),
    ('blue', $blue, $light, lighten($blue, 10%), lighten($blue, 20%)),
    ('aqua', $aqua, $light, lighten($aqua, 10%), lighten($aqua, 20%)),
    ('pink', $pink, $light, lighten($pink, 10%), lighten($pink, 20%)),
    ('orange', $orange, $light, lighten($orange, 10%), lighten($orange, 20%)),
    ('accept', $accept, $light, lighten($accept, 10%), lighten($accept, 20%)),
    ('decline', $decline, $light, lighten($decline, 10%), lighten($decline, 20%)),
    ('dark', $dark, $light, lighten($dark, 10%), lighten($dark, 20%)),
    ('black', $black, $light, lighten($black, 10%), lighten($black, 20%))
);
// Media sizes
// -------------------
$smallMediaSize: 768px;
$mediumMediaSize: 1050px;
// Nav bar top
$small-nav-size: 60px;
$medium-nav-size: 80px;
$large-nav-size: 120px;
