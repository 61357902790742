@use '../utils';

.str-chat__tooltip {
  @include utils.prevent-glitch-text-overflow;
  display: flex;
  padding: var(--str-chat__spacing-2);
  z-index: 1;
  max-width: calc(var(--str-chat__spacing-px) * 150);
  width: max-content;
}
