/*----------------------------------------

  KORDE - LIST

-----------------------------------------*/

.list {
  width: 100%;
  overflow: auto;
  list-style-type: none;
  padding: 5px;

  li {
    width: 100%;
    padding: 15px 20px;
    margin: 5px 0;
    border-radius: 3px;

    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
}

@each $item in $mainColors {
  $name: nth($item, 1);
  $bg: nth($item, 2);
  $color: nth($item, 3);

  .list-#{$name} {
    li {
      background: $bg;
      color: $color;
    }
  }
}
