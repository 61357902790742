@use 'variables';

// "Undo" the rules that break stacking context in Webkit.
// https://bugs.webkit.org/show_bug.cgi?id=160953
.webkit {
  // Fixes floating elements being cut-off by the sidebar when opened from a "position: absolute + z-index" element.
  main > .ns-dashboard {
    &:has(.requires-webkit-hack):has(app-overlay, app-popup, app-modal) {
      overflow: unset;
    }
  }

  .ns-page-header {
    // Fixes "add sub-groups" popup being cut-off by the sidebar.
    &:has(app-popup) {
      z-index: unset;
    }

    // Fixes group selector dropdown being cut-off by the sidebar on small screen.
    // This media query should match the query that adds `position: fixed` to `.ns-group-selector`.
    @media (max-width: variables.$screen-sm) {
      &:has(.ns-group-selector) {
        z-index: unset;
      }
    }
  }
}
