@use 'variables';

.ns-form {
  font-size: 16px;

  .ns-inline-fields {
    display: flex;
    align-items: flex-start;
    margin-bottom: variables.$form-margin;

    @media screen and (max-width: variables.$screen-xs) {
      flex-direction: column;
    }

    &.ns-no-gap {
      margin-bottom: 0;
    }

    &:not(.ns-form-row) {
      gap: variables.$form-margin variables.$inline-fields-margin;

      @media screen and (max-width: variables.$screen-md) {
        column-gap: variables.$inline-fields-margin-small;
      }
    }

    mat-form-field {
      margin-bottom: 0;
    }
  }

  .ns-form-row {
    display: flex;
    row-gap: variables.$form-margin;
    margin-right: calc(-#{variables.$inline-fields-margin} / 2);
    margin-left: calc(-#{variables.$inline-fields-margin} / 2);

    @media screen and (max-width: variables.$screen-md) {
      margin-right: calc(-#{variables.$inline-fields-margin-small} / 2);
      margin-left: calc(-#{variables.$inline-fields-margin-small} / 2);
    }

    @media screen and (max-width: variables.$screen-xs) {
      margin-right: 0;
      margin-left: 0;
    }

    [class*='ns-width'] {
      align-self: flex-start;
      flex-grow: 0;
      padding-left: calc(#{variables.$inline-fields-margin} / 2);
      padding-right: calc(#{variables.$inline-fields-margin} / 2);

      @media screen and (max-width: variables.$screen-md) {
        padding-left: calc(#{variables.$inline-fields-margin-small} / 2);
        padding-right: calc(#{variables.$inline-fields-margin-small} / 2);
      }

      @media screen and (max-width: variables.$screen-xs) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  mat-form-field {
    display: flex;
    margin-bottom: variables.$form-margin;
    &:not([class*='ns-width']) {
      width: 100%;
    }
    &.ns-no-gap {
      margin-bottom: 0;
    }
    &.ns-sm-gap {
      margin-bottom: 10px;
    }
  }
}
